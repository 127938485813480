import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const BroucherWrapper = styled.div`
  margin-left: 0.5rem /* 8px */;
  display: flex;
  height: fit-content;
  width: 20rem /* 320px */;
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
  scroll-padding-left: 1.5rem /* 24px */;
  scroll-padding-right: 1.5rem /* 24px */;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 0.25rem /* 4px */;
  padding-left: 1.25rem /* 20px */;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Broucher = styled.a`
  display: inline-flex;
  width: 13rem /* 208px */;
  flex: none;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex-direction: column;
  align-items: center;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  border-radius: 1.5rem /* 24px */;
  background: #fff;
	cursor: pointer;
  border: 0.2px solid ${(props) => props.theme.widgetPrimaryColor};
  margin-right: 10px;
  text-decoration: none;
`;

export const Image = styled.img`
  height: 80%;
  width: 100%;
  border-top-left-radius: 1.5rem /* 24px */;
  border-top-right-radius: 1.5rem /* 24px */;
`;

export const Title = styled.div`
  height: 25%;
  color: ${(props) => props.theme.widgetPrimaryColor};
`;
