import { useRef, useState } from "react";
import gsap from "gsap";
import { Wrapper } from "./components";
import {
  ContactCardsWrapper,
  NavButtonLeft,
  NavButtonRight,
  StyledContactCards,
} from "../ContactCards/components";
import { OfferCard } from "./OfferCard";
import { Icon } from "../../../../utils/components/Icons";

export const OfferCards = ({ offers }) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;

    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    Array.isArray(offers) && (
      <ContactCardsWrapper>
        <StyledContactCards ref={scrl} onScroll={scrollCheck}>
          {offers.map((data, idx) => (
            <OfferCard
              image_url={data?.image_url}
              title={data?.title}
              payload={data?.payload}
              key={data?.id}
            />
          ))}
        </StyledContactCards>
        {scrollX !== 0 && offers.length > 1 && (
          <NavButtonLeft
            type="button"
            onClick={() => slide(-100)}
            onMouseEnter={(e) => anim(e)}
            onMouseLeave={(e) => anim2(e)}
          >
            <Icon name="nav_left" />
          </NavButtonLeft>
        )}
        {!scrolEnd && offers.length > 1 && (
          <NavButtonRight
            type="button"
            onClick={() => slide(+100)}
            onMouseEnter={(e) => anim(e)}
            onMouseLeave={(e) => anim2(e)}
          >
            <Icon name="nav_right" />
          </NavButtonRight>
        )}
      </ContactCardsWrapper>
    )
  );
};
