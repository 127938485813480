import axios from "axios";

export const getBotResponse = async ({
  rasaServerUrl,
  sender,
  message,
  metadata,
}) => {
  try {
    const response = await axios({
      method: "POST",
      url: rasaServerUrl,
      data: {
        sender,
        message,
        metadata,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const getClientIp = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: "https://api.ipify.org/?format=json",
    });
    return response.data?.ip;
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getUserLocationMetadata = async ({ api_host }) => {
  try {
    const ip = await getClientIp();
    const response = await axios({
      method: "POST",
      url: `${api_host}/getMetadata`,
      data: { ip },
    });
    return response.data;
  } catch (error) {
    return {};
  }
};

export const saveConversation = async ({ api_host, payload }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${api_host}/saveChat`,
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.log("error occurred while saving bot response", error);
    return {};
  }
};
export const sendInvoice = async ({ api_host, payload }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${api_host}/invoice/sendInvoice`,
      data: payload,
    });

    return response.data;
  } catch (error) {
    return [];
  }
};

export const trackClickEvent = async ({ api_host, payload }) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${api_host}/clickEvent/add`,
      data: payload,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
