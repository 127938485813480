import styled from "styled-components";

export const Wrapper = styled.div``;

export const ProductIcon = styled.img`
  height: 8rem /* 128px */;
  width: 100%;
  max-width: 36rem /* 576px */;
  border-radius: 0.75rem /* 12px */;
`;
export const ProductTitle = styled.p`
  text-align: center !important;
  font-size: 1rem !important /* 16px */;
  line-height: 1.5rem !important /* 24px */;
`;

export const ProductDescripton = styled.div`
  text-align: center;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ActionButton = styled.button`
  border-radius: 9999px;
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 500;
  background-color: ${(props) => props.theme.widgetPrimaryColor};
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor};
  color: ${(props) =>
    props.title === "Main Menu" ? "#fff" : props.theme.widgetPrimaryColor};
  margin-top: 5px;
  background-color: #fff;

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: ${(props) => "white"};
  }
`;
