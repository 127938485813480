import { useDispatch } from "react-redux";
import {
  ActionButton,
  OfferCardWrapper,
  OfferImage,
  OfferImageDiv,
  OffersContentDiv,
  Title,
} from "./components";
import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../messageSlice";
import { useContext } from "react";
import AppContext from "../../../../utils/context/AppContext";

export const OfferCard = ({ title, image_url, payload }) => {
  const dispatch = useDispatch();
  const { rasaServerUrl, userId } = useContext(AppContext);
  
  return (
    <OfferCardWrapper>
      <OfferImageDiv>
        <OfferImage src={image_url} alt={title} />
      </OfferImageDiv>
      <OffersContentDiv>
        <Title>{title}</Title>
        <ActionButton
          onClick={() => {
            dispatch(
              addMessage({
                text: title,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );

            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: payload,
                sender: userId,
              })
            );
          }}
        >
          Book Now
        </ActionButton>
      </OffersContentDiv>
    </OfferCardWrapper>
  );
};
