import styled, { css } from "styled-components";
import PhoneInput from "react-phone-number-input";

export const Wrapper = styled.div`
  margin-left: 2rem /* 16px */;
  margin-top: 0.5rem /* 8px */;
  display: flex;
  height: fit-content;
  width: 80%;
  flex-direction: column;
  border-radius: 1rem /* 16px */;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
  padding: 0.5rem /* 8px */;
  p {
    display: block !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
`;

export const RowDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleDiv = styled.div`
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 700;
  color: ${(props) => props.theme.botMessageColor};
`;

export const SubTitleText = styled.p`
  font-size: 0.75rem !important /* 12px */;
  line-height: 1rem !important /* 16px */;
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
`;

export const RowDiv2 = styled.div`
  position: relative;
  margin-top: 1rem /* 16px */;
  display: flex;
  width: 93%;
  appearance: none;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  border-radius: 9999px;
  padding: 0.2rem /* 10px */;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
  --tw-border-opacity: 1;

  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;

export const FloatingLabel = styled.div`
  font-size: 14px /* 16px */;
  line-height: 1.5rem /* 24px */;
  position: absolute;
  top: -0.75rem /* -12px */;
  left: 1.25rem /* 20px */;
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
  font-weight: 700;
  z-index: 10;
  color: ${(props) => props.theme.widgetPrimaryColor};
`;

const inputBaseStyle = css`
  display: block;
  width: 80% !important;
  appearance: none !important;
  border-radius: 9999px !important;
  background-color: transparent !important;
  padding-left: 0.625rem !important /* 10px */;
  padding-right: 0.625rem !important /* 10px */;
  font-size: 13px !important;
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  border: 0 !important;
  :focus {
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }
`;

export const StyledInput = styled.input.attrs((props) => ({
  type: "text",
  placeholder: " ",
}))`
  ${inputBaseStyle}
`;

export const InputIconDiv = styled.div`
  display: inline-flex !important;
  align-items: center !important;
  border-radius: 9999px !important;
  --tw-bg-opacity: 1 !important;
  background-color: ${(props) => props.theme.widgetPrimaryColorShade} !important;
  padding: 0.625rem /* 10px */ !important;
  text-align: center !important;
  font-weight: 500 !important;
  color: ${(props) => props.theme.botMessageColor} !important;
`;

export const StyledPhoneInput = styled(PhoneInput).attrs(() => ({
  placeholder: " ",
  defaultCountry: "AE",
}))`
  display: flex !important;
  .PhoneInputInput {
    border: none !important;
    font-size: 13px !important;
    --tw-text-opacity: 1 !important;
    color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  }
  .PhoneInputInput:focus {
    outline: none !important;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 0 !important;
}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  &:focus {
    outline: none !important;
  }
  ${inputBaseStyle}
`;

export const ActionsDiv = styled.div`
  margin-top: 1rem /* 16px */;
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
`;

const buttonBaseStyle = css`
  width: 50%;
  align-self: flex-start;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-right: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
  width: 50%;
  border-radius: 9999px;
  padding:0.5rem 0.5rem /* 8px */;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  border: 0;
  :focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  background-color: ${(props) => props.theme.widgetPrimaryColor};
`;

export const SubmitButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  ${buttonBaseStyle}

  opacity: ${(props) => (props.validInput ? 1 : 0.5)};
  &:hover {
    cursor: ${(props) => (props.validInput ? "default" : "not-allowed")};
  }
`;

export const MenuButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  ${buttonBaseStyle}
`;
