import styled from "styled-components";

export const BotHeader = styled.div`
  background-color: rgb(15, 21, 63);
  display: flex;
  height: 6rem;
  align-items: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding-left: 1rem;
  gap: 0.5rem;
`;

export const FirstDiv = styled.div`
  flex-shrink: 0;
`;

export const BotLogoImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  width: 3rem;
  border-radius: 9999px;
  padding: 0.25rem;
`;

export const SecondDiv = styled.div`
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
`;

export const Title = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(props) => props.theme.header.titleColor};
`;

export const SubTitle = styled.div`
  font-size: 15px;
  font-style: normal;
  color: ${(props) => props.theme.header.subTitleColor};
`;

export const ThirdDiv = styled.div`
  display: flex;
  color: ${(props) => props.theme.header.dropDownColor};
  margin-right: 1rem;
`;


