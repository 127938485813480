import { useSelector } from "react-redux";
import { Wrapper } from "./components";

export const AgentTypingMessage = ({ showDetailView }) => {
  const agentTyping = useSelector((state) => state.messageState.agentTyping);
  const { liveAgent } = useSelector((state) => state.widgetState);
  return (
    agentTyping === true && (
      <Wrapper showDetailView={showDetailView}>
        {liveAgent?.agent_name} is typing...
      </Wrapper>
    )
  );
};
