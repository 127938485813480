import { useDispatch } from "react-redux";
import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../messageSlice";
import {
  setDetailsViewData,
  setDetailView,
  setShowDetailView,
} from "../../../../widgetSlice";

import {
  ActionButtonsDiv,
  ContactCardWrapper,
} from "../ContactCards/components";
import { ActionButton, ProductDescripton, ProductIcon, ProductTitle } from "./components";

export const ProductCard = ({
  title,
  image_url,
  description,
  widgetPrimaryColor,
  botMessageColor,
  payload,
  title_ar,
  description_ar,
  payload_ar,
  rasaServerUrl,
  info_url,
  userId,
  lang,
}) => {
  const dispatch = useDispatch();

  return (
    <ContactCardWrapper
      className={`bot-flex bot-h-fit bot-w-56 bot-flex-none bot-snap-start bot-snap-normal bot-flex-col bot-items-center bot-space-y-2 bot-rounded-2xl bot-border-[1px] bot-border-gray-200 bot-bg-white bot-px-2 bot-py-2`}
      style={{ border: `0.5px solid ${widgetPrimaryColor}` }}
    >
      <ProductIcon
        src={image_url}
        alt={lang === "ar" ? title_ar : title}
      />
      <ProductTitle className="bot-text-md bot-text-center bot-antialiased ">
        {lang === "ar" ? title_ar : title}
      </ProductTitle>
      <ProductDescripton className="bot-text-center bot-text-xs bot-antialiased bot-line-clamp-2">
        {lang === "ar" ? description_ar : description}
      </ProductDescripton>
      <ActionButtonsDiv className="bot-flex bot-flex-col bot-gap-2">
        <ActionButton
          type="button"
          className="bot-inline-flex bot-items-center bot-justify-center bot-rounded-full bot-py-2 bot-text-center bot-text-[13px] bot-font-medium"
          widgetPrimaryColor={widgetPrimaryColor}
          onClick={() => {
            dispatch(setDetailView("treatment_details"));
            dispatch(
              setDetailsViewData({
                title: lang === "en" ? title : title_ar,
                image_url,
                lang,
                info_url,
                description: lang === "en" ? description : description_ar,
                button_payload_text: lang === "en" ? payload : payload_ar,
              })
            );
            dispatch(setShowDetailView(true));
          }}
        >
          {lang === "ar" ? "اقرأ أكثر" : "Read More"}
        </ActionButton>
        <ActionButton
          type="button"
          className="bot-inline-flex bot-items-center bot-justify-center bot-rounded-full bot-py-2 bot-px-4 bot-text-center bot-text-[13px] bot-font-medium"
          widgetPrimaryColor={widgetPrimaryColor}
          onClick={() => {
            dispatch(
              addMessage({
                text: lang === "ar" ? title_ar : title,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );
            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: lang === "ar" ? payload_ar : payload,
                sender: userId,
              })
            );
          }}
        >
          {lang === "ar" ? "موعد الكتاب" : "Book Appointment"}
        </ActionButton>
      </ActionButtonsDiv>
    </ContactCardWrapper>
  );
};
