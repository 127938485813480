import { DoctorDetailsView } from "./DoctorDetailView";
import { TreatmentDetailsView } from "./TreatmentsDetailView";
import { DetailsViewWrapper } from "./component";

export const DetailView = ({ detailView }) => {
  if (detailView === "doctor_details") {
    return (
      <DetailsViewWrapper>
        <DoctorDetailsView />
      </DetailsViewWrapper>
    );
  }
  if (detailView === "treatment_details") {
    return (
      <DetailsViewWrapper>
        <TreatmentDetailsView />
      </DetailsViewWrapper>
    );
  }
};
