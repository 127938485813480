import styled from "styled-components";

export const MessageDiv = styled.div`
  display: flex;
  width: fit-content;
  min-width: 10%;
  max-width: 75%;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  align-self: flex-start;
  white-space: pre-line;
  overflow-wrap: break-word;
  border-radius: 20px;
  padding: 12px 14px 12px 14px;
  font-size: 13px;
  gap: 0.5rem;
  background-color: #e5e7eb;
`;

export const Dot = styled.div`
  height: 1px /* 8px */;
  width: 1px /* 8px */;
  border-radius: 9999px;
  padding: 0.25rem /* 4px */;
  background-color: ${(props) => props.color};
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: none;
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  animation: bounce 1s infinite;
`;
export const Dot1 = styled(Dot)`
  animation-delay: -0.32s;
`;
export const Dot2 = styled(Dot)`
  animation-delay: -0.16s;
`;
export const Dot3 = styled(Dot)``;
