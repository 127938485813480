import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setShowLauncherPopupMsg, setToggleWidget } from "../widgetSlice";
import { LauncherPopup } from "./LauncherPopup";
import BotLogo from "../../assets/img/aesmcLogo.png";
import AppContext from "../utils/context/AppContext";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  right: 1.25rem /* 20px */;
  bottom: 0.25rem /* 4px */;
  margin-right: 0.5rem /* 8px */;
  display: inline-flex;
  cursor: default;
  border-radius: 9999px;
  text-align: center;
  padding: 0.5rem /* 8px */;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: ${(props) => props.theme.widgetPrimaryColor};
  color: ${(props) => props.theme.botMessageColor};
  @media (max-width: 600px) {
    margin-right: 0.15rem /* 8px */;
    bottom: 1rem /* 4px */;
  }
`;

const BotLogoImage = styled.img`
  height: 3rem /* 48px */;
  width: 3rem /* 48px */;
  border-radius: 9999px;
`;

export const Launcher = () => {
  const dispatch = useDispatch();
  let { toggleWidget, showLauncherPopupMsg, popupMessageShown } = useSelector(
    (state) => state.widgetState
  );
  const { launcher } = useContext(AppContext);

  return (
    !toggleWidget && (
      <Wrapper
        onClick={(e) => {
          e.preventDefault();
          dispatch(setShowLauncherPopupMsg(false));
          dispatch(setToggleWidget(!toggleWidget));
        }}
      >
        <LauncherPopup
          toggleWidget={toggleWidget}
          showPopupMessage={showLauncherPopupMsg}
          popupMessageShown={popupMessageShown}
          popUpMessage={launcher?.popupMessage}
          dispatch={dispatch}
        />
        <BotLogoImage src={BotLogo} alt="bot" />
      </Wrapper>
    )
  );
};
