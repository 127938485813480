import styled from "styled-components";

const IframeWrapper = styled.iframe.attrs((props) => ({
  type: "button",
  title: "map",
  allowFullScreen: false,
  loading: "lazy",
  width: "80%",
}))`
  margin-left: 2rem /* 16px */;
  border-radius: 0.5rem /* 8px */;
  margin-top: 0.5rem /* 16px */;

  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
`;

export const GoogleMaps = ({ data }) => {
  return <IframeWrapper src={data.src} />;
};
