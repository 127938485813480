import { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { SendButton, SendButtonIcon, TypingArea, Wrapper } from "./components";
import AppContext from "../utils/context/AppContext";
import { useLiveChatSocket } from "../utils/hooks/useLiveChatSocket";
import { addMessage } from "../MessageContainer/messageSlice";

export const LiveChatKeypad = ({ showDetailView }) => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const inputRef = useRef();
  const theme = useContext(AppContext);
  const { widgetPrimaryColor } = theme;
  const { access_token } = useSelector((state) => state.widgetState);
  let userId = useSelector((state) => state.widgetState.userId);

  const { sendUserMessage, userTypingIndicator, userStopTyping } =
    useLiveChatSocket({ userId, access_token });
  const userTyping = useSelector((state) => state.messageState.userTyping);
  const userTypingPlaceholder = useSelector(
    (state) => state.messageState.userTypingPlaceholder
  );

  const handleSubmit = async () => {
    if (userInput.length > 0) {
      const payload = {
        text: userInput,
        sender: "USER",
        messageType: "text",
        ts: new Date(),
      };
      dispatch(addMessage(payload));
      setUserInput("");
      sendUserMessage(userInput);
    }
  };

  return (
    <Wrapper>
      <TypingArea
        id="chat"
        rows="1"
        placeholder={userTypingPlaceholder}
        value={userInput}
        ref={inputRef}
        onFocus={(e) => {
          userTypingIndicator();
        }}
        onBlur={(e) => {
          userStopTyping();
        }}
        onChange={(e) => {
          setUserInput(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            inputRef.current.blur();
            handleSubmit();
          }
        }}
        readOnly={!userTyping}
      />
      <SendButton
        type="submit"
        userInput={userInput}
        color={widgetPrimaryColor}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SendButtonIcon />
      </SendButton>
    </Wrapper>
  );
};
