import { useRef, useState } from "react";
import { ContactCard } from "./ContactCard";
import gsap from "gsap";

import {
  ContactCardsWrapper,
  NavButtonLeft,
  NavButtonRight,
  StyledContactCards,
} from "./components";
import { Icon } from "../../../../utils/components/Icons";

export const ContactCards = ({ doctorDetails, button, lang }) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;

    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    Array.isArray(doctorDetails) && (
      <ContactCardsWrapper>
        <StyledContactCards ref={scrl} onScroll={scrollCheck}>
          {doctorDetails.map((data, idx) => {
            return (
              <ContactCard data={data} key={idx} button={button} lang={lang} />
            );
          })}
        </StyledContactCards>
        {scrollX !== 0 && doctorDetails.length > 1 && (
          <NavButtonLeft
            type="button"
            onClick={() => slide(-100)}
            onMouseEnter={(e) => anim(e)}
            onMouseLeave={(e) => anim2(e)}
          >
            <Icon name="nav_left" />
          </NavButtonLeft>
        )}
        {!scrolEnd && doctorDetails.length > 1 && (
          <NavButtonRight
            type="button"
            onClick={() => slide(+100)}
            onMouseEnter={(e) => anim(e)}
            onMouseLeave={(e) => anim2(e)}
          >
            <Icon name="nav_right" />
          </NavButtonRight>
        )}
      </ContactCardsWrapper>
    )
  );
};
