import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSocketMode } from "../../../widgetSlice";

export const LiveAgent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSocketMode("liveAgent"));
  }, [dispatch]);
};
