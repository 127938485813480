import { BotMessage } from "./BotMessage";
import { Textmessage as UserMessage } from "./UserMessage/TextMessage";

export const Message = ({ message, index, previousItem, nextItem }) => {
  let isUser = message.sender === "USER";
  const ts = message.ts;

  let prevBySameAuthor = false;
  let nextBySameAuthor = false;
  let startsSequence = true;
  let endsSequence = true;

  if (previousItem) {
    prevBySameAuthor = previousItem.sender === message.sender;

    if (prevBySameAuthor) {
      startsSequence = false;
    }
  }
  if (nextItem) {
    nextBySameAuthor = nextItem.sender === message.sender;

    if (nextBySameAuthor) {
      endsSequence = false;
    }
  }

  if (isUser) {
    return <UserMessage messageItem={message} key={index} />;
  }

  return (
    <BotMessage
      messageItem={message}
      key={index}
      index={index}
      startsSequence={startsSequence}
      endsSequence={endsSequence}
      ts={ts}
    />
  );
};
