import { ArrowDownCircleIcon } from "@heroicons/react/24/outline";
import { DocumentIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";


export const Wrapper = styled.div`
  margin-top: 0.5rem /* 8px */;
`;

export const AttachmentDiv = styled.div`
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const AttachmentContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
`;

export const AttachmentContent = styled.div`
  display: flex;
  width: 80%;
  height: 80px;
  &:not([hidden]) ~ &:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
`;
export const AttachmentContentInnerDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  overflow-wrap: break-word;
  border-radius: 0.5rem /* 8px */;
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  padding-left: 0.5rem /* 8px */;
  padding-right: 0.5rem /* 8px */;
`;

export const FileIcon = styled(DocumentIcon)`
  height: 2rem /* 32px */;
  width: 2rem /* 32px */;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
`;

export const FileName = styled.div`
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  width: 80%;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

export const DownloadIcon = styled(ArrowDownCircleIcon)`
  height: 2rem /* 32px */;
  width: 2rem /* 32px */;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
`;

export const FileMetadata = styled.div`
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 500;
`;

export const TimestampDiv = styled.div`
  display: flex;
  font-size: 10px;
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
`;
