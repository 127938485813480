import { useRef, useState } from "react";
import gsap from "gsap";

import { Broucher, BroucherWrapper, Image, Title, Wrapper } from "./components";
import { NavButtonLeft, NavButtonRight } from "../ContactCards/components";
import { Icon } from "../../../../utils/components/Icons";

export const BroucherCards = ({ brochures }) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;

    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <Wrapper>
      <BroucherWrapper ref={scrl} onScroll={scrollCheck}>
        {brochures.map((data, idx) => {
          const { title, pdf_link, image_url } = data;
          return (
            <Broucher
              key={idx}
              href={pdf_link}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={image_url} alt={title} />
              <Title>{title}</Title>
            </Broucher>
          );
        })}
      </BroucherWrapper>
      {scrollX !== 0 && brochures.length > 1 && (
        <NavButtonLeft
          type="button"
          onClick={() => slide(-100)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
        >
          <Icon name={"nav_left"} />
        </NavButtonLeft>
      )}
      {!scrolEnd && brochures.length > 1 && (
        <NavButtonRight
          type="button"
          onClick={() => slide(+100)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
        >
          <Icon name="nav_right" />
        </NavButtonRight>
      )}
    </Wrapper>
  );
};
