/* eslint-disable no-unused-vars */
import { AgentAttachments } from "./AgentAttachment";
import { BookingForm } from "./BookingForm";
import { ButtonResponse } from "./Buttons";
import { BlogCard } from "./Cards/BlogCard";
import { BroucherCards } from "./Cards/BroucherCards";
import { ContactCards } from "./Cards/ContactCards";
import { OfferCards } from "./Cards/OfferCards";
import { PackageCard } from "./Cards/PackageCard";
import { ProductCards } from "./Cards/ProductCards";
import { StackCards } from "./Cards/StackCards";
import DateTimePicker from "./DateTimePicker";
import { GoogleMaps } from "./GoogleMaps";
import { LiveAgent } from "./LiveAgent";
import { MainMenu } from "./MainMenu";
import { PaymentForm } from "./Payment";
import { TextMessage } from "./TextMessage";
import { Tiles } from "./Tiles";
import { YTVideo } from "./YTVideo";

export const BotMessage = ({
  messageItem,
  startsSequence,
  endsSequence,
  index,
}) => {
  const botResponse = [];
  let showBot = false;

  if (endsSequence) {
    showBot = true;
  }
  if (messageItem.type === "text") {
    botResponse.push(
      <TextMessage
        startsSequence={startsSequence}
        endsSequence={endsSequence}
        showBot={showBot}
        text={messageItem.text}
        isAgent={messageItem?.isAgent}
        key="text_message"
        ts={messageItem.ts}
      />
    );
  }
  if (messageItem.type === "buttons") {
    if (messageItem?.text) {
      botResponse.push(
        <TextMessage
          showBot={showBot}
          text={messageItem.text}
          key="text_message"
          showBotAvatar={showBot}
        />
      );
    }
    botResponse.push(
      <ButtonResponse
        buttons={messageItem.buttons}
        index={index}
        key="button"
        showBotAvatar={showBot}
      />
    );
  }
  if (messageItem.type === "agent_attachment") {
    botResponse.push(
      <AgentAttachments
        messageItem={messageItem}
        key={"agent_attachment"}
        index={index}
      />
    );
  }
  if (messageItem.type === "custom") {
    const {
      messageType,
      data,
      text,
      buttons,
      payload,
      color,
      textColor,
      url,
      user_details,
      button,
      lang,
      info_url,
      offers,
    } = messageItem;

    if (text) {
      botResponse.push(
        <TextMessage
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showBot={showBot}
          text={text}
          key="text_message"
          ts={messageItem.ts}
        />
      );
    }
    if (messageType === "main_menu") {
      botResponse.push(
        <MainMenu showBot={showBot} data={data} index={index} key="main_menu" />
      );
    }
    if (messageType === "booking_form") {
      botResponse.push(
        <BookingForm
          key="booking_form"
          payload={payload}
          user_details={user_details}
          lang={lang}
        />
      );
    }
    // Doctor Cards
    if (messageType === "cards_type_1") {
      botResponse.push(
        <ContactCards
          doctorDetails={data}
          key="cards_type_1"
          button={button}
          lang={lang}
        />
      );
      if (buttons) {
        botResponse.push(
          <ButtonResponse
            showBot={showBot}
            buttons={buttons}
            index={index}
            key="quick_replies"
          />
        );
      }
    }
    // Ayurveda Treatment & Cure Cards
    if (messageType === "cards_type_3") {
      botResponse.push(
        <ProductCards data={data} key="product_cards" lang={lang} />
      );
    }

    if (messageType === "map") {
      botResponse.push(<GoogleMaps data={data} key="google_map" />);
    }

    // ayur package cards
    if (messageType === "stack_cards") {
      botResponse.push(<StackCards key="stack_cards" data={data} />);
    }

    // ayur package card
    if (messageType === "package_card") {
      botResponse.push(
        <PackageCard key="package_card" data={data} lang={lang} />
      );
    }

    // media cards
    if (messageType === "cards_type_2") {
      botResponse.push(<BroucherCards brochures={data} key="brochure_cards" />);
      if (buttons) {
        botResponse.push(
          <ButtonResponse
            showBot={showBot}
            buttons={buttons}
            index={index}
            key="quick_replies"
          />
        );
      }
    }

    if (messageType === "date_time_picker") {
      botResponse.push(
        <DateTimePicker key="date_time_picker" payload={payload} lang={lang} />
      );
    }
    // departments grid
    if (messageType === "tiles") {
      botResponse.push(
        <Tiles
          data={data}
          payload={payload}
          key="tiles"
          color={color}
          textColor={textColor}
          lang={lang}
        />
      );
    }
    if (messageType === "live_agent") {
      botResponse.push(<LiveAgent data={data} key="live_agent" />);
    }

    if (messageType === "payment_form") {
      botResponse.push(
        <PaymentForm
          key="payment_form"
          payload={payload}
          data={data}
          index={index}
          lang={lang}
        />
      );
    }

    if (messageType === "blog_card") {
      botResponse.push(
        <BlogCard info_url={info_url} lang={lang} key="blog_card" />
      );
    }

    // youtube video
    if (messageType === "yt_video") {
      botResponse.push(<YTVideo url={url} key="yt_video" />);
    }

    if (messageType === "offer_cards") {
      botResponse.push(<OfferCards offers={offers} />);
    }
  }

  return botResponse;
};
