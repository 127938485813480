import { useContext } from "react";
import { useDispatch } from "react-redux";

import {
  setDetailsViewData,
  setDetailView,
  setShowDetailView,
} from "../../../../widgetSlice";

import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../messageSlice";
import AppContext from "../../../../utils/context/AppContext";
import {
  ActionButton,
  ActionButtonsDiv,
  ContactCardWrapper,
  Name,
  NameDiv,
  ProfilePic,
  QualificationText,
  SpecializationText,
} from "./components";

export const ContactCard = ({ data, button, lang }) => {
  const {
    name,
    image_url,
    specialization_ar,
    qualification,
    specialization,
    payload,
    arabic_name,
  } = data;
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;

  const dispatch = useDispatch();
  return (
    <ContactCardWrapper>
      
      <ProfilePic src={image_url} alt={name} />
      <NameDiv>
        <Name>{lang === "ar" ? arabic_name : name}</Name>
      </NameDiv>
      <div>
        <SpecializationText>
          {lang === "ar" ? specialization_ar : specialization}
        </SpecializationText>
      </div>
      <div>
        <QualificationText className="bot-text-xs bot-text-gray-500">
          {qualification}
        </QualificationText>
      </div>
      <ActionButtonsDiv>
        <ActionButton
          type="button"
          onClick={() => {
            dispatch(
              setDetailsViewData({
                ...data,
                lang,
                button_payload_text: button.payload,
              })
            );
            dispatch(setShowDetailView(true));
            dispatch(setDetailView("doctor_details"));
          }}
        >
          {lang === "ar" ? "عرض الصفحة الشخصية" : "View Profile"}
        </ActionButton>
        <ActionButton
          type="button"
          onClick={() => {
            dispatch(
              addMessage({
                text: `${button.payload}${lang === "ar" ? arabic_name : name}`,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );
            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: payload,
                sender: userId,
              })
            );
          }}
        >
          {button.title}
        </ActionButton>
      </ActionButtonsDiv>
    </ContactCardWrapper>
  );
};
