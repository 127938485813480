import styled from "styled-components";
import { AnimateHoverDiv } from "../../../../utils/components/StyledComponents";

export const Wrapper = styled.div`
  display: flex;
  margin-left: 1rem;
  width: 20rem /* 320px */;
  flex-direction: column;
  padding-left: 1rem /* 16px */;
  padding-top: 0.5rem /* 8px */;
`;

export const StackCardDiv = styled(AnimateHoverDiv)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 5rem /* 96px */;
  width: 12rem /* 208px */;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  border-radius: 0.75rem /* 12px */;
  padding: 1.25rem /* 20px */;
  text-align: center;
  margin-top: 0.5rem;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};

  p {
    display: block !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  &:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
`;

export const Title = styled.div`
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
`;
export const SubTitle = styled.p`
  font-size: 1.5rem !important /* 24px */;
  line-height: 2rem !important /* 32px */;
  font-weight: 500 !important;
`;

export const Button = styled.button`
  border: 0;
  position: absolute;
  top: 2.5rem /* 32px */;
  right: -1.25rem /* -20px */;
  display: inline-flex;
  align-items: center;
  border-radius: 9999px;
  font-size: 13px;

  padding: 0.625rem /* 10px */;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  :focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;
