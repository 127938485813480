import { Widget } from "./Widget";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import { persistor, store } from "./store";

export const ConnectedWidget = () => {
  const api_host =
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_PROD_API_HOST
      : process.env.REACT_APP_DEV_API_HOST;
  const rasaServerUrl =
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_PROD_BOT_URL
      : process.env.REACT_APP_DEV_BOT_URL;

  const props = {
    widgetPrimaryColor: "#0f153f",
    widgetPrimaryColorShade: "#e7e8ec",
    botTitle: "AESMC Helpdesk",
    botSubtitle: "Health Care Assistant",
    userId: null,
    rasaServerUrl,
    botAvatar: "",
    init: "/show_lang_option",
    botMessageColor: "#0f153f",
    userMessageColor: "#0f153f",
    api_host,
    header: {
      titleColor: "#fff",
      subTitleColor: "#fff",
      dropDownColor: "#fff",
      dropDownItemColor: "#fff",
    },
    launcher: {
      popupMessage: "Hi There 👋, How can I help you?",
      iconColor: "#fff",
    },
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Widget {...props} />
      </PersistGate>
    </Provider>
  );
};
