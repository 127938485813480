import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-left: 2.4rem;
`;

export const BlogDiv = styled.div`
  display: flex;
  width: fit-content;
  min-width: 10%;
  max-width: 75%;
  flex-direction: column;
  white-space: pre-line;
  overflow-wrap: break-word;
  border-radius: 0.5rem /* 8px */;
  font-size: 13px;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
`;

export const ContentDiv = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 0.5rem /* 8px */;
  padding: 0.5rem /* 8px */;
  color: ${(props) => props.theme.widgetPrimaryColor};
`;

export const Image = styled.img`
    height: 10rem;
    width: 15rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
`;

export const IconDiv = styled(ArrowTopRightOnSquareIcon)`
  height: 1.25rem /* 20px */;
  width: 1.25rem /* 20px */;
`;
