
import { BlogDiv, ContentDiv, IconDiv, Image, Wrapper } from "./components";

import BlogSvg from './Blog.svg'
export const BlogCard = ({ info_url, lang }) => {
  return (
    <Wrapper>
      <BlogDiv>
        <Image src={BlogSvg} alt="imgAlt" />
        <ContentDiv
          style={{}}
          onClick={() => {
            window.open(info_url, "_blank");
          }}
        >
          <IconDiv />
          <span>{lang === "en" ? "Show Blogs" : "عرض المدونات"}</span>
        </ContentDiv>
      </BlogDiv>
    </Wrapper>
  );
};
