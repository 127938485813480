import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  padding: 0.5rem /* 8px */;
`;

export const NavIconDiv = styled.div`
  width: 20%;
  color: ${(props) => props.theme.botMessageColor};
`;

export const HeaderText = styled.span`
  width: 50%;
  font-weight: 700;
`;

export const ContentDiv1 = styled.div`
  display: flex;
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  padding: 0.5rem /* 8px */;
`;

export const ProfileImage = styled.img`
  height: 8rem /* 128px */;
  width: 8rem /* 128px */;
  border-radius: 1rem /* 16px */;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 1px solid ${(props) => props.theme.botMessageColor};
`;

export const BioDiv = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 1rem;
`;

export const BioDivText1 = styled.span`
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 700;
  color: ${(props) => props.theme.botMessageColor};
`;

export const BioDivText2 = styled.span`
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
`;
export const Divider = styled.hr`
  margin-top: 0.5rem /* 8px */;
  width: 100%;
  border-top: 0.5px solid rgb(0 0 0 / 0.1);
  border-left: 0;
`;

export const BioDivText3Div = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  margin-top: 0.5rem /* 8px */;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
`;
export const BioDivText3 = styled.span`
  font-weight: 600;
`;

export const ContentDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  padding: 0.5rem /* 8px */;
`;

export const ContentDiv2Text1 = styled.div`
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
`;

export const ContentDiv2Text2 = styled.div`
  width: 20rem /* 352px */;
  padding: 0.5rem /* 8px */;
  padding-left: 1rem;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  text-align: ${(props) => (props.lang === "en" ? "left" : "right")};
  ul {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 0px !important;
    list-style: disc !important;
  }
`;
