import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  bottom: 11.5%;
  z-index: 50;
  margin-top: auto;
  margin-left: 1.25rem /* 20px */;
  font-size: 12px;
  font-weight: 700;
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  visibility: ${(props) => (props.showDetailView ? "hidden" : "visible")};
`;
