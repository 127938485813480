import { useContext } from "react";
import { useDispatch } from "react-redux";

import BotLogo from "../../../../assets/img/aesmcLogo.png";

import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";
import {
  BotIconDiv,
  Button,
  ButtonsWrapper,
  QuickRepliesWrapper,
  Wrapper,
} from "./components";
import AppContext from "../../../utils/context/AppContext";
import { BotIcon } from "../TextMessage/components";

const QuickReplies = ({ dispatch, buttons }) => {
  const theme = useContext(AppContext);
  const { userId, rasaServerUrl } = theme;
  return (
    <QuickRepliesWrapper>
      {buttons.map((item, idx) => (
        <Button
          type="button"
          key={idx}
          title={item.title}
          onClick={async (e) => {
            e.preventDefault();
            const payload = {
              text: item.title,
              sender: "USER",
              messageType: "text",
              ts: new Date(),
            };
            dispatch(addMessage(payload));
            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: item.payload,
                sender: userId,
              })
            );
          }}
        >
          {item.title}
        </Button>
      ))}
    </QuickRepliesWrapper>
  );
};

const Buttons = ({ dispatch, buttons }) => {
  const theme = useContext(AppContext);
  const { userId, rasaServerUrl } = theme;
  return (
    <ButtonsWrapper>
      {buttons.map((item, idx) => (
        <Button
          type="button"
          key={idx}
          title={item.title}
          onClick={async (e) => {
            e.preventDefault();
            const payload = {
              text: item.title,
              sender: "USER",
              messageType: "text",
              ts: new Date(),
            };
            dispatch(addMessage(payload));
            dispatch(toggleBotTyping(true));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: item.payload,
                sender: userId,
              })
            );
          }}
        >
          {item.title}
        </Button>
      ))}
    </ButtonsWrapper>
  );
};

export const ButtonResponse = ({ buttons, index, showBotAvatar }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <BotIconDiv>
        <BotIcon showIcon={showBotAvatar} src={BotLogo} alt="Bot Logo" />
      </BotIconDiv>
      {buttons.length > 2 ? (
        <QuickReplies dispatch={dispatch} buttons={buttons} index={index} />
      ) : (
        <Buttons dispatch={dispatch} buttons={buttons} index={index} />
      )}
    </Wrapper>
  );
};
