import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { useDispatch } from "react-redux";
import {
  addMessage,
  fetchBotResponse,
  removeMessage,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";

import {
  ActionButtonsDiv,
  Divider,
  Form,
  FormTitle,
  MenuButton,
  PayNowButton,
  PaymentMessage,
} from "./components";
import { saveConversation, sendInvoice } from "../../../utils/api";
import { generateOrderId, isText, isButtons } from "../../../utils/helpers";
import AppContext from "../../../utils/context/AppContext";
import { LoadingIcon } from "../../../utils/components/Icons/LoadingIcon";

dayjs.extend(timezone);
dayjs.extend(utc);

export const CheckoutForm = ({
  name,
  _package,
  price,
  payment_success,
  index,
  email_id,
  lang,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const theme = useContext(AppContext);
  const { api_host, rasaServerUrl, userId } = theme;
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment(
      {
        elements,
        // confirmParams: {
        //   // Make sure to change this to your payment completion page
        //   return_url: "http://localhost:3000",
        // },
        redirect: "if_required",
      }
      // Disable the default next action handling.
      // { handleActions: false }
    );

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          dispatch(removeMessage(index));
          const invoice_payload = {
            item: _package,
            order_number: generateOrderId(),
            order_date: dayjs
              .tz(new Date(), "Asia/Dubai")
              .format("MMM D, YYYY h:mm A"),
            user_name: name,
            item_price: price,
            email_id,
            lang,
          };
          await sendInvoice({ api_host, payload: invoice_payload });
          if (isText(payment_success)) {
            dispatch(
              addMessage({
                ...payment_success,
                ...{ sender: "BOT", ts: new Date(), type: "text" },
              })
            );
            await saveConversation({
              api_host,
              payload: {
                sender: "BOT",
                sender_id: userId,
                text: payment_success?.text,
              },
            });
          } else if (isButtons(payment_success)) {
            dispatch(
              addMessage({
                ...payment_success,
                ...{ sender: "BOT", ts: new Date(), type: "buttons" },
              })
            );
            await saveConversation({
              api_host,
              payload: {
                sender: "BOT",
                sender_id: userId,
                buttons: payment_success?.buttons,
              },
            });
          }
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          console.log("requires 3D authentication");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    }
    setIsLoading(false);
  };

  return (
    stripe && (
      <Form id="payment-form" onSubmit={handleSubmit}>
        <FormTitle>
          {lang === "ar" ? "فاتورة من AESMC" : "Invoice from AESMC"}
        </FormTitle>
        <Divider />
        <PaymentElement id="payment-element" />
        {/* Show any error or success messages */}
        {message && (
          <PaymentMessage id="payment-message">{message}</PaymentMessage>
        )}
        {elements && (
          <ActionButtonsDiv>
            <PayNowButton
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">
                {isLoading ? (
                  <LoadingIcon />
                ) : lang === "ar" ? (
                  "ادفع الآن"
                ) : (
                  "Pay Now"
                )}
              </span>
            </PayNowButton>
            <MenuButton
              disabled={isLoading || !stripe || !elements}
              onClick={(e) => {
                e.preventDefault();
                const _payload = {
                  text: "Main Menu",
                  sender: "USER",
                  messageType: "text",
                  ts: new Date(),
                };
                dispatch(addMessage(_payload));
                dispatch(toggleBotTyping(true));
                dispatch(toggleUserTyping(false));
                dispatch(
                  fetchBotResponse({
                    rasaServerUrl,
                    message: "/main_menu",
                    sender: userId,
                  })
                );
              }}
            >
              {lang === "ar" ? "القائمة الرئيسية" : "Main Menu"}
            </MenuButton>
          </ActionButtonsDiv>
        )}
      </Form>
    )
  );
};
