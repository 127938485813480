import { useContext } from "react";

import AppContext from "../../utils/context/AppContext";
import { MarkdownText } from "../../utils/components/MarkdownText";
import { formattedTs } from "../../utils/helpers";
import { Message, MessageDiv, TimestampDiv, Wrapper } from "./components";

export const Textmessage = ({ messageItem }) => {
  const { text, ts } = messageItem;
  const theme = useContext(AppContext);
  const { userMessageColor } = theme;
  return (
    <Wrapper>
      <MessageDiv>
        <Message color={userMessageColor} dir="auto">
          <MarkdownText text={text} />
        </Message>
        <TimestampDiv>{formattedTs(ts)}</TimestampDiv>
      </MessageDiv>
    </Wrapper>
  );
};
