/* eslint-disable no-unused-vars */
import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  addMessage,
  fetchBotResponse,
  removeAllMessages,
  resetBot,
  resetMessageState,
  setUserTypingPlaceholder,
  toggleBotTyping,
  toggleUserTyping,
} from "../MessageContainer/messageSlice";
import {
  resetSocketMode,
  setDetailView,
  setShowDetailView,
} from "../widgetSlice";
import {
  HeaderDropdown,
  List,
  ListItem,
  IconDiv,
  ListItemText,
} from "./components";
import AppContext from "../utils/context/AppContext";
import { Icon } from "../utils/components/Icons";
import { useDetectClick } from "../utils/hooks/useDetectClick";
import { trackClickEvent } from "../utils/api";

const INACTIVITY_TIMEOUT = 300 * 1000;

const dropdownMenu = [
  {
    title: "Restart",
  },
  {
    title: "Clear Chat",
  },
  {
    title: "Whatsapp Chat",
  },
];

export const Shortcuts = ({ showDropdown, setShowDropdown }) => {
  const dispatch = useDispatch();

  const { rasaServerUrl, userId, api_host } = useContext(AppContext);
  const handleClearChatButton = () => {
    dispatch(removeAllMessages());
    dispatch(toggleBotTyping(false));
    dispatch(toggleUserTyping(true));
    dispatch(setUserTypingPlaceholder("Type you message..."));
    setShowDropdown(!showDropdown);
  };
  const handleRestartButton = () => {
    dispatch(resetMessageState());
    dispatch(resetSocketMode("rasaServer"));
    setShowDropdown(!showDropdown);
    dispatch(
      resetBot({
        rasaServerUrl,
        message: "/restart",
        sender: userId,
      })
    );
    dispatch(setDetailView({}));
    dispatch(setShowDetailView(false));
  };
  const handleWhatsappChat = async () => {
    window.open("https://wa.link/7zcpgh", "_blank");
    await trackClickEvent({
      api_host,
      payload: {
        user_id: userId,
        channel: "webchat",
        event_name: "whatsapp_chat",
      },
    });
    setShowDropdown(!showDropdown);
  };
  const dropdownRef = useDetectClick({
    setShowModal: setShowDropdown,
  });
  return (
    <HeaderDropdown ref={dropdownRef}>
      <List>
        {dropdownMenu.map((item, idx) => {
          const { title } = item;
          return (
            <ListItem
              key={idx}
              onClick={() => {
                if (title === "Clear Chat") {
                  handleClearChatButton();
                } else if (title === "Whatsapp Chat") {
                  handleWhatsappChat();
                } else {
                  handleRestartButton();
                }
              }}
            >
              <IconDiv>
                <Icon name={title} />
              </IconDiv>
              <div>
                <ListItemText>{title}</ListItemText>
              </div>
            </ListItem>
          );
        })}
      </List>
    </HeaderDropdown>
  );
};
