import {
  base64ToArrayBuffer,
  formattedTs,
  getBytes,
  getFileType,
} from "../../../utils/helpers";
import { BotIcon, BotIconDiv } from "../TextMessage/components";
import {
  AttachmentContent,
  AttachmentContentInnerDiv,
  AttachmentContentWrapper,
  AttachmentDiv,
  FileIcon,
  FileMetadata,
  FileName,
  TimestampDiv,
  Wrapper,
} from "./components";
import BotLogo from "../../../../assets/img/aesmcLogo.png";

export const AgentAttachments = ({ messageItem }) => {
  const { file, ts, fileData } = messageItem;

  return (
    <Wrapper>
      <AttachmentDiv className="bot-flex bot-space-x-2">
        <BotIconDiv className={`bot-flex bot-w-5 bot-items-end`}>
          <BotIcon src={BotLogo} alt="Bot Logo" />
        </BotIconDiv>
        <AttachmentContentWrapper>
          <AttachmentContent>
            <div
              className="bot-flex bot-h-full bot-w-full bot-flex-col bot-space-y-1  bot-break-words bot-rounded-lg bot-bg-slate-200 bot-py-2 bot-px-2"
              dir="auto"
            >
              <AttachmentContentInnerDiv>
                <FileIcon />
                <FileName>{file.name}</FileName>
                <FileIcon
                  onClick={(e) => {
                    const arrayBuffer = base64ToArrayBuffer(fileData);
                    const fileURL = window.URL.createObjectURL(
                      new Blob([arrayBuffer])
                    );
                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${file.name}`;
                    alink.onload = () => {
                      URL.revokeObjectURL(alink.href);
                    };
                    alink.click();
                  }}
                />
              </AttachmentContentInnerDiv>
              <FileMetadata>
                <span>{getFileType(file.type)}</span>
                <span>•</span>
                <span>{getBytes(file.size)}</span>
              </FileMetadata>
            </div>
          </AttachmentContent>

          <TimestampDiv>{formattedTs(ts)}</TimestampDiv>
        </AttachmentContentWrapper>
      </AttachmentDiv>
    </Wrapper>
  );
};
