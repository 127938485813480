import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBotResponse, sendInvoice } from "../utils/api";

const initialState = {
  messages: [],
  botTyping: false,
  userTyping: true,
  userTypingPlaceholder: "Type your message here...",
  userGreeted: false,
  agentTyping: false,
  lastMessageTs: null,
};

export const fetchBotResponse = createAsyncThunk(
  "messages/fetchBotResponse",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const metadata = {
      ...state.widgetState.userLocationMetadata,
      ...{ sourceUrl: window.location.href },
    };
    const response = await getBotResponse({ ...payload, ...{ metadata } });
    await new Promise((r) => setTimeout(r, 2000));
    return response;
  }
);

export const endBotConversations = createAsyncThunk(
  "messages/endBotConversations",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const metadata = {
      ...state.widgetState.userLocationMetadata,
      ...{ sourceUrl: window.location.href },
    };
    const response = await getBotResponse({ ...payload, ...{ metadata } });
    // await new Promise((r) => setTimeout(r, 2000));
    return response;
  }
);

export const resetBot = createAsyncThunk(
  "messages/resetBot",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const metadata = state.widgetState.userLocationMetadata;
    await getBotResponse({ ...payload, ...{ metadata } });
  }
);

export const sendInvoiceMail = createAsyncThunk(
  "messages/sendInvoiceMail",
  async (payload, thunkAPI) => {
    const response = await sendInvoice(payload);
    return response;
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      if (action.payload.sender === "USER") {
        state.messages = state.messages.map((message) => {
          if (message.type === "custom") {

            if (message.text) {
              message = {
                text: message.text,
                sender: "BOT",
                type: "text",
                ts: message.ts,
              };
            } else if (message.messageType !== "blog_card") {
              message = {};
            }
          }
          if (message.type === "buttons") {
            // message.buttons = [];
            message = {};
          }
          return message;
        });
      }
      state.messages.push(action.payload);
    },
    removeCustomMessages: (state) => {
      state.messages = state.messages.map((message) => {
        if (message.type === "custom") {
          if (message.text) {
            message = {
              text: message.text,
              sender: "BOT",
              type: "text",
              ts: message.ts,
            };
          } else if (message.messageType !== "blog_card") {
            message = {};
          }
        }
        if (message.type === "buttons") {
          // message.buttons = [];
          message = {};
        }
        return message;
      });
    },
    removeMessage: (state, action) => {
      const messageIndex = action.payload;
      state.messages = state.messages.map((message, index) => {
        if (index === messageIndex) {
          if (message.type === "custom") {
            if (message.text) {
              message = {
                text: message.text,
                sender: "BOT",
                type: "text",
                ts: message.ts,
              };
            }
          }
          if (Object.hasOwnProperty.call(message, "buttons")) {
            message.buttons = [];
          }
          return message;
        } else {
          return message;
        }
      });
    },
    resetMessageState: (state) => {
      return initialState;
    },
    removeAllMessages: (state, action) => {
      state.messages = [];
    },
    toggleUserTyping: (state, action) => {
      state.userTyping = action.payload;
    },
    toggleBotTyping: (state, action) => {
      state.botTyping = action.payload;
      state.userTypingPlaceholder = action.payload
        ? "Please wait for bot response..."
        : "Type your message here...";
    },
    setUserTypingPlaceholder: (state, action) => {
      state.userTypingPlaceholder = action.payload;
    },
    setUserGreeted: (state, action) => {
      state.userGreeted = action.payload;
    },
    toggleAgentTyping: (state, action) => {
      state.agentTyping = action.payload;
    },
    setLastMessageTs: (state, action) => {
      state.lastMessageTs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendInvoiceMail.fulfilled, (state, action) => {
    });
    builder.addCase(fetchBotResponse.fulfilled, (state, action) => {
      state.botTyping = false;
      state.userTyping = true;
      state.lastMessageTs = new Date();
      state.userTypingPlaceholder = "Type your message here...";
      const messages = action.payload;
      if (messages.length > 0) {
        for (let index = 0; index < messages.length; index += 1) {
          const message = messages[index];
          // messageType: text
          if (message?.text) {
            state.messages.push({
              text: message.text,
              sender: "BOT",
              type: "text",
              ts: new Date(),
            });
          }

          // messageType: image
          if (message?.image) {
            state.messages.push({
              src: message.image,
              sender: "BOT",
              type: "image",
              ts: new Date(),
            });
          }

          // messageType: buttons
          if (message?.buttons) {
            if (message.buttons.length > 0) {
              state.messages.push({
                buttons: message.buttons,
                sender: "BOT",
                type: "buttons",
                ts: new Date(),
                callback: true,
              });
            }
          }

          if (message?.custom) {
            state.messages.push({
              ...message.custom,
              sender: "BOT",
              type: "custom",
              ts: new Date(),
            });
          }
        }
      } else {
        state.messages.push({
          text: "I am facing some issues. Could you please try again later? 😅",
          sender: "BOT",
          type: "text",
          ts: new Date(),
        });
      }
    });
    builder.addCase(endBotConversations.fulfilled, (state, action) => {
      state.botTyping = false;
      state.userTyping = true;
      // state.lastMessageTs = null;
      state.userTypingPlaceholder = "Type your message here...";
      const messages = action.payload;
      state.messages = state.messages.map((message) => {
        if (message.type === "custom") {

          if (message.text) {
            message = {
              text: message.text,
              sender: "BOT",
              type: "text",
              ts: message.ts,
            };
          } else if (message.messageType !== "blog_card") {
            message = {};
          }
        }
        if (message.type === "buttons") {
          // message.buttons = [];
          message = {};
        }
        return message;
      });
      if (messages.length > 0) {
        for (let index = 0; index < messages.length; index += 1) {
          const message = messages[index];
          // messageType: text
          if (message?.text) {
            state.messages.push({
              text: message.text,
              sender: "BOT",
              type: "text",
              ts: new Date(),
            });
          }

          // messageType: image
          if (message?.image) {
            state.messages.push({
              src: message.image,
              sender: "BOT",
              type: "image",
              ts: new Date(),
            });
          }

          // messageType: buttons
          if (message?.buttons) {
            if (message.buttons.length > 0) {
              state.messages.push({
                buttons: message.buttons,
                sender: "BOT",
                type: "buttons",
                ts: new Date(),
                callback: true,
              });
            }
          }

          if (message?.custom) {
            // if (message.text) {
            //   message = {
            //     text: message.text,
            //     sender: "BOT",
            //     type: "text",
            //     ts: message.ts,
            //   };
            // }
            // if (message.type === "buttons") {
            //   message.quick_replies = [];
            // }
            state.messages.push({
              ...message.custom,
              sender: "BOT",
              type: "custom",
              ts: new Date(),
            });
          }
        }
      } else {
        // state.messages.push({
        //   text: "I am facing some issues, could you please try again later 😅",
        //   sender: "BOT",
        //   type: "text",
        //   ts: new Date(),
        // });
      }
    });
  },
});

export const {
  addMessage,
  removeMessage,
  removeAllMessages,
  toggleBotTyping,
  toggleUserTyping,
  setUserTypingPlaceholder,
  setUserGreeted,
  resetMessageState,
  toggleAgentTyping,
  setLastMessageTs,
  removeCustomMessages,
} = messagesSlice.actions;

export default messagesSlice.reducer;
