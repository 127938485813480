import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const MarkdownText = ({ text }) => {
  return (
    <ReactMarkdown
      children={text}
      remarkPlugins={[remarkGfm]}
      linkTarget={"_blank"}
      dir="auto"
    />
  );
};
