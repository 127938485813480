import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  widgetConnected: false,
  toggleWidget: false,
  userId: null,
  socketMode: "rasaServer",
  access_token: "",
  userLocationMetadata: null,
  showDetailView: false,
  detailView: null,
  detailsViewData: {},
  showLauncherPopupMsg: false,
  popupMessageShown: false,
  liveAgent: {},
};

export const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    setWidgetConnectionStatus: (state, action) => {
      const connectionStatus = action.payload;
      state.widgetConnected = connectionStatus;
    },
    setToggleWidget: (state, action) => {
      state.toggleWidget = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setSocketMode: (state, action) => {
      state.socketMode = action.payload;
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    resetSocketMode: (state, action) => {
      state.socketMode = action.payload;
    },
    setUserLocationMetadata: (state, action) => {
      state.userLocationMetadata = action.payload;
    },
    setDetailView: (state, action) => {
      state.detailView = action.payload;
    },
    setShowDetailView: (state, action) => {
      state.showDetailView = action.payload;
    },
    setDetailsViewData: (state, action) => {
      state.detailsViewData = action.payload;
    },
    setShowLauncherPopupMsg: (state, action) => {
      state.showLauncherPopupMsg = action.payload;
    },
    setPopupMessageShown: (state, action) => {
      state.popupMessageShown = action.payload;
    },
    setLiveAgent: (state, action) => {
      state.liveAgent = action.payload;
    },
  },
});

export const {
  setWidgetConnectionStatus,
  setToggleWidget,
  setUserId,
  setSocketMode,
  setAccessToken,
  resetSocketMode,
  setUserLocationMetadata,
  setShowDetailView,
  setDetailsViewData,
  setDetailView,
  setShowLauncherPopupMsg,
  setPopupMessageShown,
  setLiveAgent,
} = widgetSlice.actions;

export default widgetSlice.reducer;
