import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import AppContext from "../context/AppContext";
import {
  addMessage,
  toggleAgentTyping,
} from "../../MessageContainer/messageSlice";
import { arrayBufferToBase64 } from "../helpers";
import { setLiveAgent, setSocketMode } from "../../widgetSlice";

export const useLiveChatSocket = ({ userId, access_token }) => {
  const socketRef = useRef();
  const dispatch = useDispatch();
  let roomRef = useRef();
  let theme = useContext(AppContext);
  const { socketMode } = useSelector((state) => state.widgetState);
  const { api_host } = theme;
  roomRef.current = userId;
  const socket_host =
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_PROD_SOCKET_HOST
      : process.env.REACT_APP_DEV_SOCKET_HOST;

  useEffect(() => {
    dispatch(setSocketMode("liveAgent"));

    if (socketMode === "liveAgent") {
      socketRef.current = socketIOClient(socket_host, {});
      socketRef.current.on("connect", function () {
        socketRef.current.emit("agent_request", { user_id: userId });
      });

      socketRef.current.on("agent_request_assigned", async (response) => {
        const { message, agent_id, agent_name } = response;
        dispatch(setLiveAgent({ agent_id, agent_name }));
        dispatch(
          addMessage({
            text: message,
            sender: "BOT",
            messageType: "text",
            type: "text",
            ts: new Date(),
          })
        );
      });
      socketRef.current.on("agent_messaged", (response) => {
        const { message } = response;
        if (message) {
          dispatch(
            addMessage({
              text: message,
              sender: "BOT",
              messageType: "text",
              type: "text",
              isAgent: true,
              ts: new Date(),
            })
          );
        }
      });

      socketRef.current.on("agent_request_completed", () => {
        dispatch(setSocketMode("rasaServer"));
        dispatch(
          addMessage({
            text: "I'm glad that the live agent was able to assist you. Is there anything else I can help you with or any other question I can answer for you?\n\n You can click on below button for more information or ask your query",
            sender: "BOT",
            messageType: "text",
            type: "text",
            ts: new Date(),
          })
        );
        dispatch(
          addMessage({
            buttons: [
              {
                title: "Main Menu",
                payload: "/main_menu",
              },
            ],
            sender: "BOT",
            type: "buttons",
            ts: new Date(),
            callback: true,
          })
        );
        socketRef.current.disconnect();
      });

      socketRef.current.on("request_timeout", (response) => {
        const { message } = response;
        dispatch(setSocketMode("rasaServer"));

        if (message) {
          dispatch(
            addMessage({
              text: message,
              sender: "BOT",
              messageType: "text",
              type: "text",
              ts: new Date(),
            })
          );
          dispatch(
            addMessage({
              buttons: [
                {
                  title: "Main Menu",
                  payload: "/main_menu",
                },
              ],
              sender: "BOT",
              type: "buttons",
              ts: new Date(),
              callback: true,
            })
          );
          socketRef.current.disconnect();
        }
      });
      socketRef.current.on("request_expired", (response) => {
        const { message } = response;
        dispatch(setSocketMode("rasaServer"));
        socketRef.current.disconnect();
        if (message) {
          dispatch(
            addMessage({
              text: message,
              sender: "BOT",
              messageType: "text",
              type: "text",
              ts: new Date(),
            })
          );
          dispatch(
            addMessage({
              buttons: [
                {
                  title: "Main Menu",
                  payload: "/main_menu",
                },
              ],
              sender: "BOT",
              type: "buttons",
              ts: new Date(),
              callback: true,
            })
          );
        }
      });
      socketRef.current.on("agent_not_available", (response) => {
        const { message } = response;
        dispatch(setSocketMode("rasaServer"));
        socketRef.current.disconnect();
        if (message) {
          dispatch(
            addMessage({
              text: message,
              sender: "BOT",
              messageType: "text",
              type: "text",
              ts: new Date(),
            })
          );
        }
      });

      socketRef.current.on("disconnect", (reason) => {
        dispatch(setSocketMode("rasaServer"));
      });
      socketRef.current.on("agent_typing", () => {
        dispatch(toggleAgentTyping(true));
      });
      socketRef.current.on("agent_stopped_typing", () => {
        dispatch(toggleAgentTyping(false));
      });

      socketRef.current.on("agent_file_upload", ({ message }) => {
        dispatch(
          addMessage({
            file: message,
            fileData: arrayBufferToBase64(message.file),
            sender: "BOT",
            messageType: "agent_attachment",
            type: "agent_attachment",
            isDisabled: false,
            ts: new Date(),
          })
        );
      });

      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [access_token, api_host, dispatch, socketMode, socket_host, userId]);

  const sendUserMessage = (message) => {
    socketRef.current.emit("user_message", {
      message,
      user_id: roomRef.current,
    });
  };
  const userTypingIndicator = (user_id) => {
    socketRef.current.emit("user_typing", { user_id: roomRef.current });
  };
  const userStopTyping = (user_id) => {
    socketRef.current.emit("user_stopped_typing", { user_id: roomRef.current });
  };

  return { sendUserMessage, userTypingIndicator, userStopTyping };
};
