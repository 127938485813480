import styled from "styled-components";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewportList } from "react-viewport-list";

import {
  fetchBotResponse,
  setUserGreeted,
  setUserTypingPlaceholder,
  toggleBotTyping,
  toggleUserTyping,
} from "./messageSlice";
import AppContext from "../utils/context/AppContext";
import { Message } from "./Messages";
import { BotTyping } from "./BotMessage/BotTyping";

const Wrapper = styled.div`
  visibility: visible;
  position: absolute;
  top: 5rem;
  display: flex;
  height: 70%;
  width: 100%;
  flex-direction: column;
  overflow-y: auto;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-width: 0px;
  background-color: #fff;
  padding-top: 0.5rem;

    /* width */
    scrollbar-width: thin;
  scrollbar-color: #0f153f transparent;
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: "transparent"
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius:20px;
    background-clip: "padding-box";
    background: ${(props) =>
      props.theme.widgetPrimaryColor}; /* color of the scroll thumb */,
    border-radius: "20px"; /* roundness of the scroll thumb */,
    border: "none"; /* creates padding around scroll thumb */,
  }

`;
export const MessageContainer = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messageState.messages);
  const userGreeted = useSelector((state) => state.messageState.userGreeted);
  const ref = useRef(null);
  const listRef = useRef(null);
  const theme = useContext(AppContext);

  const { init, widgetPrimaryColor, rasaServerUrl, userId } = theme;

  useEffect(() => {
    if (!userGreeted && messages.length < 1) {
      dispatch(setUserGreeted(true));
      dispatch(setUserTypingPlaceholder("Please wait while bot is typing..."));
      dispatch(toggleBotTyping(true));
      dispatch(toggleUserTyping(false));
      dispatch(
        fetchBotResponse({
          rasaServerUrl,
          message: init,
          sender: userId,
        })
      );
    }
  }, [dispatch, init, messages.length, rasaServerUrl, userGreeted, userId]);

  useEffect(() => {
    listRef.current.scrollToIndex({ index: messages.length - 1 });
  }, [messages]);

  return (
    <Wrapper
      key="message_container"
      widgetPrimaryColor={widgetPrimaryColor}
      className="scroll-container"
      ref={ref}
    >
      <ViewportList
        ref={listRef}
        viewportRef={ref}
        items={messages}
        itemMinSize={40}
        margin={8}
        initialIndex={messages.length - 1}
      >
        {(item, index) => (
          <Message
            message={item}
            index={index}
            key={index}
            previousItem={messages[index - 1]}
            nextItem={messages[index + 1]}
          />
        )}
      </ViewportList>
      <BotTyping />
    </Wrapper>
  );
};
