import styled from "styled-components";

export const ContentDiv = styled.div`
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  padding: 0.5rem /* 8px */;
`;

export const NavIconDiv = styled.div`
  color: ${(props) => props.theme.botMessageColor} !important;
`;

export const ProductTitle = styled.span`
  width: 100%;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 500;
  text-align: center;
`;

export const ProductImage = styled.img`
  height: 13rem /* 208px */;
  width: 100%;
  border-radius: 1.5rem /* 24px */;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
`;

export const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem /* 8px */;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const Description = styled.div`
  
  white-space: normal;
  padding: 0.5rem /* 8px */;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
`;
