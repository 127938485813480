import styled from "styled-components";
import { AnimateHoverDiv } from "../../../utils/components/StyledComponents";

export const Wrapper = styled.div`
  display: flex;
  width: 20rem /* 320px */;
  flex-direction: column;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  padding-left: 1rem /* 16px */;
`;

export const MenuDiv = styled(AnimateHoverDiv)`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 90%;
  cursor: pointer;
  align-items: center;
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  margin-top: 0.5rem;
  border-radius: 0.75rem /* 12px */;
  padding: 0.625rem /* 10px */;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
  &:hover {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
`;

export const IconDiv = styled.div`
  margin-right: 0.5rem /* 8px */;
  display: inline-flex;
  align-items: center;
  border-radius: 0.75rem /* 12px */;
  padding: 0.5rem /* 8px */;
  text-align: center;
  font-weight: 500;
  background-color: ${(props) => props.theme.widgetPrimaryColorShade};
  color: ${(props) => props.theme.botMessageColor};
`;

export const Title = styled.div`
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: ${(props) => props.theme.widgetPrimaryColor};
`;
export const SubTitle = styled.p`
  font-size: 13px !important;
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
  display: block !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
`;
