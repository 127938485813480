import { useDispatch } from "react-redux";
import {
  addMessage,
  fetchBotResponse,
  removeMessage,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../messageSlice";
import { useContext } from "react";

import { Button, StackCardDiv, SubTitle, Title, Wrapper } from "./components";
import AppContext from "../../../../utils/context/AppContext";
import { Icon } from "../../../../utils/components/Icons";

const Card = ({
  title,
  subtitle,
  icon,
  payload,
  messageIndex,
  color,
  bgColor,
  buttonColor,
  rasaServerUrl,
  userId,
}) => {
  const dispatch = useDispatch();
  return (
    <StackCardDiv
      color={color}
      backgroundColor={bgColor}
      onClick={async () => {
        dispatch(removeMessage(messageIndex));
        dispatch(
          addMessage({
            text: title,
            sender: "USER",
            messageType: "text",
            ts: new Date(),
          })
        );
        dispatch(toggleBotTyping(true));
        dispatch(toggleUserTyping(false));
        dispatch(
          fetchBotResponse({
            rasaServerUrl,
            message: payload,
            sender: userId,
          })
        );
        // sendMessage(payload);
      }}
    >
      <div>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </div>
      <Button
        type="button"
        backgroundColor={buttonColor}
        style={{ backgroundColor: buttonColor, color }}
      >
        <Icon name="nav_right" />
      </Button>
    </StackCardDiv>
  );
};

export const StackCards = ({ data, index }) => {
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;
  return (
    <Wrapper>
      {data.map((item, idx) => {
        const { icon, title, subtitle, payload, color, bgColor, buttonColor } =
          item;
        return (
          <Card
            title={title}
            subtitle={subtitle}
            icon={icon}
            payload={payload}
            key={idx}
            messageIndex={index}
            color={color}
            bgColor={bgColor}
            buttonColor={buttonColor}
            rasaServerUrl={rasaServerUrl}
            userId={userId}
          />
        );
      })}
    </Wrapper>
  );
};
