import { useContext } from "react";
import { useDispatch } from "react-redux";

import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../messageSlice";
import AppContext from "../../../../utils/context/AppContext";
import {
  ActionButton,
  Divider,
  Feature,
  FeaturesDiv,
  Price,
  Title,
  Wrapper,
} from "./components";
import { Icon } from "../../../../utils/components/Icons";

export const PackageCard = ({ data, lang }) => {
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;
  const { title, price, features, color, payload } = data;
  const dispatch = useDispatch();
  return (
    <Wrapper color={color}>
      <Title>{title}</Title>
      <Price color={color}>{price}</Price>
      <Divider />
      <FeaturesDiv>
        {features.map((feature, idx) => (
          <Feature key={idx}>{feature}</Feature>
        ))}
      </FeaturesDiv>
      <ActionButton
        color={color}
        onClick={() => {
          dispatch(
            addMessage({
              text: title,
              sender: "USER",
              messageType: "text",
              ts: new Date(),
            })
          );
          dispatch(toggleBotTyping(true));
          dispatch(toggleUserTyping(false));
          dispatch(
            fetchBotResponse({
              rasaServerUrl,
              message: payload,
              sender: userId,
            })
          );
        }}
      >
        {lang === "ar" ? "اختر الباقة" : "Choose plan"}
        <Icon name={"nav_right_button"} />
      </ActionButton>
    </Wrapper>
  );
};
