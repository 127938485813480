import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";
import { hoverAnimation } from "../utils/components/StyledComponents";

export const Wrapper = styled.div`
  position: relative;
  visibility: visible;
  margin-top: auto;
  display: flex;
  height: 8%;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid;
  border-color: #d1d5db;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 0px 5px 0 5px;
  visibility: ${(props) => (props.showDetailView ? "hidden" : "visible")};
`;

export const ShortcutDiv = styled.div`
  display: flex;
  /* border: 1px solid red; */
  padding: 0.25rem;
  border-radius: 999px;

  ${(props) => {
    return (
      props.showDropdown && {
        "background-color": props.theme.widgetPrimaryColorShade,
        transform: "rotate(-25deg) !important",
      }
    );
  }}

  :hover {
    background-color: ${(props) => props.theme.widgetPrimaryColorShade};
    animation: ${hoverAnimation} 2s ease 0s 1 normal forwards;
  }
`;

export const TypingArea = styled.textarea`
  resize: none !important;

  margin-right: 1rem /* 16px */ !important;
  display: block !important;
  width: 100% !important;
  padding: 10px !important;
  font-size: 0.875rem /* 14px */ !important;
  line-height: 1.25rem /* 20px */ !important;
  border: 0 !important;
  /* border: 1px solid red; */
  cursor: ${(props) =>
    props.userTyping ? "default" : "not-allowed"} !important;
  &:focus {
    outline: 0 !important;
  }
  &::-webkit-scrollbar {
    display: none !important;
  }
`;

export const SendButton = styled.button`
  cursor: ${(props) =>
    props.userInput.trim().length > 1 ? "default" : "not-allowed"} !important;
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding: 0.5rem;
  border: 0;
  background: #f3f4f6;
  &:hover {
    animation: ${hoverAnimation} 2s ease 0s 1 normal forwards;
  }
`;

export const SendButtonIcon = styled(PaperAirplaneIcon)`
  width: 1.2rem /* 24px */;
  height: 1.2rem /* 24px */;
  transform: rotate(-45deg);
  stroke-width: 1.5;
`;

export const HeaderDropdown = styled.div`
  position: absolute;
  left: 1.25rem;
  bottom: 4.4rem;
  z-index: 50;
  width: fit-content;
  cursor: default;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-radius: 0.75rem;
  background-color: ${(props) => props.theme.dropDownItemColor};
`;

export const List = styled.ul`
  border-radius: 0.5rem !important;
  font-size: 13px !important;
  color: ${(props) => props.theme.widgetPrimaryColor} !important;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor} !important;
  background-color: #fff !important;
  padding-left: 0 !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 0px !important;
  list-style: disc !important;
`;

export const ListItem = styled.li`
  display: flex;
  padding-top: 0.2rem /* 4px */;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  &:hover {
    color: #fff;
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`;
export const ListItemText = styled.span`
  display: block;
  padding: 0.5rem;
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
