import styled from "styled-components";
import { AgentIcon } from "./AgentIcon";

const IconDiv = styled.i`
  width: 16px;
  height: 16px;
`;

const IconDiv2 = styled(IconDiv)`
  margin-left: 0.5rem /* 8px */;
  margin-right: -0.25rem /* -4px */;
`;

const IconDiv3 = styled.i`
  width: 20px;
  height: 20px;
`;
const MenuIconDiv = styled.i`
  width: 24px;
  height: 24px;
`;

export const Icon = ({ name, showIcon }) => {
  if (name === "Restart") {
    return <IconDiv className="fa-solid fa-arrow-rotate-left" />;
  }
  if (name === "Clear Chat") {
    return <IconDiv className="fa-solid fa-broom" />;
  }
  if (name === "Close") {
    return <IconDiv className="fa-solid fa-xmark" />;
  }
  if (name === "Live Agent") {
    return <IconDiv className="fa-solid fa-user-doctor-message" />;
  }

  if (name === "chevron_down") {
    return <MenuIconDiv className="fa-solid fa-circle-chevron-down" />;
  }
  if (name === "shortcuts") {
    return <IconDiv3 className="fa-light fa-bolt" />;
  }

  if (name === "nav_right") {
    return <IconDiv className="fa-solid fa-chevron-right" />;
  }
  if (name === "nav_right_button") {
    return <IconDiv2 className="fa-solid fa-chevron-right" />;
  }
  if (name === "nav_left") {
    return <IconDiv className="fa-solid fa-chevron-left" />;
  }

  if (name === "arrow_left") {
    return <IconDiv className="fa-solid fa-arrow-left" />;
  }

  if (name === "agent_icon") {
    return <AgentIcon showIcon={showIcon} />;
  }

  if (name === "calender_icon") {
    return <IconDiv className="fa-light fa-calendar-check" />;
  }

  if (name === "info_icon") {
    return <IconDiv className="fa-light fa-circle-info" />;
  }

  if (name === "user_icon") {
    return <IconDiv className="fa-light fa-user" />;
  }
  if (name === "email_icon") {
    return <IconDiv className="fa-light fa-envelope" />;
  }
  if (name === "phone_icon") {
    return <IconDiv className="fa-light fa-phone" />;
  }
  if (name === "offers") {
    return <MenuIconDiv className="fa-light fa-tags" />;
  }

  if (name === "doctors") {
    return <MenuIconDiv className="fa-light fa-user-doctor" />;
  }
  if (name === "book_appointment") {
    return <MenuIconDiv className="fa-light fa-calendar-check" />;
  }
  if (name === "book_pcr_test") {
    return <MenuIconDiv className="fa-light fa-vial-circle-check" />;
  }
  if (name === "human_agent") {
    return <MenuIconDiv className="fa-light fa-user-doctor-message" />;
  }

  if (name === "Whatsapp Chat") {
    return (
      <IconDiv>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          strokeWidth={1}
          fill="currentColor"
        >
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
        </svg>
      </IconDiv>
    );
  }
  if (name === "whatsapp") {
    return (
      <MenuIconDiv>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          strokeWidth={1}
        >
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
        </svg>
      </MenuIconDiv>
    );
  }
  if (name === "callback") {
    return <MenuIconDiv className="fa-light fa-phone-arrow-down-left" />;
  }
  if (name === "contact") {
    return <MenuIconDiv className="fa-light fa-address-book" />;
  }
  if (name === "media") {
    return <MenuIconDiv className="fa-light fa-file-arrow-down" />;
  }
  if (name === "ayurveda") {
    return <MenuIconDiv className="fa-light fa-mortar-pestle" />;
  }
  if (name === "departments") {
    return <MenuIconDiv className="fa-light fa-network-wired" />;
  }
  if (name === "treatments") {
    return <MenuIconDiv className="fa-light fa-notes-medical" />;
  }

  if (name === "offers") {
    return <MenuIconDiv className="fa-light fa-badge-check" />;
  }

  if (name === "Dentistry") {
    return <MenuIconDiv className="fa-light fa-tooth" />;
  }
  if (name === "Pediatrics") {
    return <MenuIconDiv className="fa-light fa-stethoscope" />;
  }
  if (name === "Orthodontics") {
    return <MenuIconDiv className="fa-light fa-teeth" />;
  }
  if (name === "Dermatology") {
    return <MenuIconDiv className="fa-light fa-person-dots-from-line" />;
  }
  if (name === "Orthopaedic") {
    return <MenuIconDiv className="fa-light fa-bone-break" />;
  }
  if (name === "Family Medicine") {
    return <MenuIconDiv className="fa-light fa-users-medical" />;
  }
  if (name === "Obstetric & Gynaecology") {
    return <MenuIconDiv className="fa-light fa-person-pregnant" />;
  }
  if (name === "Oral & Maxillofacial Surgery") {
    // return <MenuIconDiv className="fa-light fa-tooth" />;
    return <MenuIconDiv className="fa-light fa-teeth-open" />;
  }
  if (name === "ENT") {
    return <MenuIconDiv className="fa-light fa-ear" />;
  }
  if (name === "General Medicine") {
    return <MenuIconDiv className="fa-light fa-capsules" />;
  }
  if (name === "Internal Medicine") {
    return <MenuIconDiv className="fa-light fa-users-medical" />;
  }
  if (name === "Pain Relief Unit") {
    return <MenuIconDiv className="fa-light fa-face-exhaling" />;
  }
  if (name === "Physiotherapy") {
    return <MenuIconDiv className="fa-light fa-bone" />;
  }
  if (name === "Radiology") {
    return <MenuIconDiv className="fa-light fa-x-ray" />;
  }
  if (name === "Occupational Health") {
    return <MenuIconDiv className="fa-light fa-user-helmet-safety" />;
  }
  if (name === "Ayurvedic") {
    return <MenuIconDiv className="fa-light fa-mortar-pestle" />;
  }

  if (name === "Hijama") {
    return <MenuIconDiv className="fa-light fa-vital" />;
  }
  if (name === "Show More") {
    return <MenuIconDiv className="fa-light fa-grid-2" />;
  }

  if (name === "Show Less") {
    return <MenuIconDiv className="fa-light fa-angles-up" />;
  }
};
