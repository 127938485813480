import { useContext } from "react";

import BotLogo from "../../../../assets/img/aesmcLogo.png";
import AppContext from "../../../utils/context/AppContext";
import { Icon } from "../../../utils/components/Icons";
import { MarkdownText } from "../../../utils/components/MarkdownText";
import { formattedTs } from "../../../utils/helpers";
import {
  BotIcon,
  BotIconDiv,
  Message,
  MessageDiv,
  TimestampDiv,
  Wrapper,
} from "./components";

export const TextMessage = ({
  text,
  startsSequence,
  endsSequence,
  showBot,
  isAgent,
  ts,
}) => {
  const theme = useContext(AppContext);
  const { botMessageColor } = theme;
  const position = [
    "message",
    `${startsSequence ? "start" : ""}`,
    `${endsSequence ? "end" : ""}`,
  ]
    .join(" ")
    .trim();
  return (
    <Wrapper>
      <BotIconDiv>
        {isAgent ? (
          <Icon name="agent_icon" showIcon={showBot} />
        ) : (
          <BotIcon
            showIcon={showBot}
            borderColor={botMessageColor}
            src={BotLogo}
            alt="Bot Logo"
          />
        )}
      </BotIconDiv>
      <MessageDiv>
        <Message color={botMessageColor} dir="auto" position={position}>
          <MarkdownText text={text} />
        </Message>
        {showBot && <TimestampDiv>{formattedTs(ts)}</TimestampDiv>}
      </MessageDiv>
    </Wrapper>
  );
};
