import styled from "styled-components";

export const Wrapper = styled.iframe.attrs({
  title: "Youtube Video",
})`
  margin-left: 2rem /* 28px */;
  align-self: flex-start;
  border-radius: 0.5rem /* 8px */;
  width: 80%;
  height: 90%;
  border: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
`;
