import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Form = styled.form`
  margin-top: 0.5rem /* 8px */;
  margin-left: 1.5rem /* 8px */;
  display: flex;
  width: 18rem /* 320px */;
  flex-direction: column;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  border-radius: 0.75rem /* 12px */;
  padding: 1rem 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-border-opacity: 1;
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor};
`;

export const FormTitle = styled.div`
  text-align: center;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
`;

export const Divider = styled.div`
  width: 100%;
  border: 0.8px solid ${(props) => props.theme.widgetPrimaryColor} !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const PaymentMessage = styled.div`
  text-align: center;
  font-size: 13px;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
`;

export const ActionButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  --tw-space-x-reverse: 0;
  margin-top: 1rem;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const ActionButton = styled.button`
  display: flex;
  height: 100%;
  width: 50%;
  align-self: flex-start;
  text-align: center;
  border-radius: 0.75rem /* 12px */;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 0 !important;
`;

export const PayNowButton = styled(ActionButton)`
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor} !important;
  color: ${(props) => props.theme.widgetPrimaryColor};
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: #fff;
  }
`;

export const MenuButton = styled(ActionButton)`
  background-color: ${(props) => props.theme.widgetPrimaryColor};
  color: white;
  margin-left: 0.5rem;
`;
