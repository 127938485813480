import { useContext, useRef, useState } from "react";

import gsap from "gsap";

import { ProductCard } from "./ProductCard";
import AppContext from "../../../../utils/context/AppContext";
import {
  ContactCardsWrapper,
  NavButtonLeft,
  NavButtonRight,
  StyledContactCards,
} from "../ContactCards/components";

import { Icon } from "../../../../utils/components/Icons";

export const ProductCards = ({ data, lang }) => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const theme = useContext(AppContext);
  const { widgetPrimaryColor, rasaServerUrl, userId, botMessageColor } = theme;

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;

    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <ContactCardsWrapper>
      <StyledContactCards
        className="bot-ml-2 bot-flex bot-h-fit bot-w-80 bot-snap-x bot-scroll-pl-6 bot-scroll-pr-6 bot-space-x-2 bot-overflow-x-auto bot-scroll-smooth bot-p-1 bot-pl-5 bot-no-scrollbar"
        ref={scrl}
        onScroll={scrollCheck}
      >
        {data.map((data, idx) => {
          const {
            title,
            title_ar,
            description_ar,
            image_url,
            description,
            info_url,
            payload,
            payload_ar,
          } = data;
          return (
            <ProductCard
              title={title}
              image_url={image_url}
              description={description}
              info_url={info_url}
              key={idx}
              widgetPrimaryColor={widgetPrimaryColor}
              payload={payload}
              lang={lang}
              title_ar={title_ar}
              description_ar={description_ar}
              payload_ar={payload_ar}
              rasaServerUrl={rasaServerUrl}
              userId={userId}
              botMessageColor={botMessageColor}
            />
          );
        })}
      </StyledContactCards>
      {scrollX !== 0 && data.length > 1 && (
        <NavButtonLeft
          type="button"
          className="bot-absolute bot-left-[2px] bot-top-[45%] bot-z-20 bot-inline-flex bot-translate-y-[-45%] bot-items-center bot-rounded-full bot-p-2.5 bot-text-center bot-text-[13px] bot-font-medium "
          onClick={() => slide(-100)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
          widgetPrimaryColor={widgetPrimaryColor}
        >
          <Icon name="nav_left" />
        </NavButtonLeft>
      )}
      {!scrolEnd && data.length > 1 && (
        <NavButtonRight
          type="button"
          className="bot-absolute bot-right-[10px] bot-top-[45%] bot-z-20 bot-inline-flex bot-translate-y-[-45%] bot-items-center bot-rounded-full bot-p-2.5 bot-text-center bot-text-[13px] bot-font-medium bot-text-blue-700"
          onClick={() => slide(+100)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
          widgetPrimaryColor={widgetPrimaryColor}
        >
          <Icon name="nav_right" />
        </NavButtonRight>
      )}
    </ContactCardsWrapper>
  );
};
