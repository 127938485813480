import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../utils/context/AppContext";


// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === "DEV"
    ? process.env.REACT_APP_DEV_PAYMENT_KEY
    : process.env.REACT_APP_PROD_PAYMENT_KEY
);
export const PaymentForm = ({ data, index, lang }) => {
  const [clientSecret, setClientSecret] = useState("");
  const {
    price,
    name,
    package: _package,
    payment_success,
    email: email_id,
    phone_number,
  } = data;

  const theme = useContext(AppContext);
  const { api_host } = theme;
  useEffect(() => {
    // Create PaymentIntent as soon as the payment component is rendered
    fetch(`${api_host}/payment/secret`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        price: price.replace("AED", "").trim(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.client_secret);
      });
  }, [_package, api_host, email_id, price, phone_number]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    appearance: {
      theme: "flat",
      labels: "floating",
      variables: {
        colorPrimary: "#0570de",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontSizeBase: "1rem",
        spacingUnit: "2px",
        borderRadius: "10px",
        spacingGridRow: "10px",
        spacingGridColumn: "10px",
        // See all possible variables below
      },
    },
  };
  return (
    clientSecret && (
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm
          name={name}
          price={price}
          _package={_package}
          payment_success={payment_success}
          email_id={email_id}
          index={index}
          lang={lang}
        />
      </Elements>
    )
  );
};
