import styled from "styled-components";
import { StyledScrollBar } from "../../../../utils/components/StyledComponents";

export const DetailsViewWrapper = styled.div`
  position: absolute;
  top: 5rem;
  display: flex;
  height: 85.5%;
  width: 100%;
  cursor: default;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 1rem /* 16px */;
  z-index: 50;
  background-color: #fff;
`;

export const DetailsViewDiv = styled(StyledScrollBar)`
  height: 92%;
  overflow-y: auto;
  padding: 0.25rem /* 4px */;
  text-align: ${(props) => (props.lang === "en" ? "left" : "right")};
`;

export const ActionButtonsDiv = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.widgetPrimaryColor};
  border-color: rgb(255 255 255);
`;

export const ActionButton = styled.a`
  display: flex;
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
  width: 50%;
  align-items: center;
  justify-content: center;
  gap: 0.5rem /* 8px */;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration: none;
`;
