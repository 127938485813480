import { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  addMessage,
  fetchBotResponse,
  setUserTypingPlaceholder,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";

import { IconDiv, TileDiv, Title, Wrapper } from "./components";
import { Icon } from "../../../utils/components/Icons";
import AppContext from "../../../utils/context/AppContext";

const Tile = ({ name, onClickHandler, icon }) => {
  return (
    <TileDiv onClick={onClickHandler}>
      <IconDiv>
        <Icon name={icon} />
      </IconDiv>
      <Title dir="auto">{name}</Title>
    </TileDiv>
  );
};

export const Tiles = ({ data, payload, lang }) => {
  const initalList = data.slice(0, 5);
  const [dataGrid, setDataGrid] = useState(initalList);
  const [showMore, setShowMore] = useState(true);
  const dispatch = useDispatch();
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;
  return (
    <Wrapper>
      {[
        dataGrid.map((item, idx) => {
          const { name, icon, department } = item;
          return (
            <Tile
              key={idx}
              name={name}
              icon={icon}
              onClickHandler={() => {
                dispatch(
                  addMessage({
                    text: name,
                    sender: "USER",
                    messageType: "text",
                    ts: new Date(),
                  })
                );
                dispatch(toggleUserTyping(false));
                dispatch(toggleBotTyping(true));
                dispatch(
                  setUserTypingPlaceholder("Please wait for bot response...")
                );

                dispatch(
                  fetchBotResponse({
                    rasaServerUrl,
                    message: `${payload}${JSON.stringify({
                      department,
                    })}`,
                    sender: userId,
                  })
                );
              }}
            />
          );
        }),
        showMore ? (
          <Tile
            key={"show_more"}
            name={lang === "en" ? "Show more" : "أظهر المزيد"}
            icon="Show More"
            onClickHandler={() => {
              setShowMore(false);
              setDataGrid(data);
            }}
          />
        ) : (
          <Tile
            key={"show_less"}
            icon="Show Less"
            name={lang === "en" ? "Show less" : "عرض أقل"}
            onClickHandler={() => {
              setShowMore(true);
              setDataGrid(data.slice(0, 5));
            }}
          />
        ),
      ]}
    </Wrapper>
  );
};
