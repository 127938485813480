import BotLogo from "../../assets/img/aesmcLogo.png";
import {
  BotHeader,
  BotLogoImg,
  FirstDiv,
  SecondDiv,
  SubTitle,
  ThirdDiv,
  Title,
} from "./components";

import { setToggleWidget } from "../widgetSlice";
import { useDispatch } from "react-redux";
import { Icon } from "../utils/components/Icons";

export const Header = () => {
  const dispatch = useDispatch();

  return (
    <BotHeader key="bot_header">
      <FirstDiv>
        <BotLogoImg src={BotLogo} alt="Bot Logo" />
      </FirstDiv>

      <SecondDiv>
        <Title>AESMC Helpdesk</Title>
        <SubTitle>Health Care Assistant</SubTitle>
      </SecondDiv>

      <ThirdDiv
        onClick={() => {
          dispatch(setToggleWidget(false));
        }}
      >
        <Icon name="chevron_down" />
      </ThirdDiv>
    </BotHeader>
  );
};
