import { useContext } from "react";
import { useDispatch } from "react-redux";

import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";
import AppContext from "../../../utils/context/AppContext";
import { Icon } from "../../../utils/components/Icons";
import { IconDiv, MenuDiv, SubTitle, Title, Wrapper } from "./components";
import { trackClickEvent } from "../../../utils/api";

const Menu = ({
  title,
  subtitle,
  icon,
  payload,
  userId,
  rasaServerUrl,
  api_host,
}) => {
  const dispatch = useDispatch();
  return (
    <MenuDiv
      onClick={async () => {
        if (icon === "whatsapp") {
          window.open(payload, "_blank");
          await trackClickEvent({
            api_host,
            payload: {
              user_id: userId,
              channel: "webchat",
              event_name: "whatsapp_chat",
            },
          });
        } else {
          const _payload = {
            text: title,
            sender: "USER",
            messageType: "text",
            ts: new Date(),
          };

          dispatch(addMessage(_payload));
          dispatch(toggleBotTyping(true));
          dispatch(toggleUserTyping(false));
          dispatch(
            fetchBotResponse({
              rasaServerUrl,
              message: payload,
              sender: userId,
            })
          );
        }
      }}
    >
      <IconDiv>
        <Icon name={icon} />
      </IconDiv>
      <div>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
      </div>
    </MenuDiv>
  );
};

export const MainMenu = ({ data, index }) => {
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId, api_host } = theme;
  return (
    <Wrapper>
      {data.map((item, idx) => {
        const { icon, title, subtitle, payload } = item;
        return (
          <Menu
            title={title}
            subtitle={subtitle}
            icon={icon}
            payload={payload}
            key={idx}
            messageIndex={index}
            rasaServerUrl={rasaServerUrl}
            userId={userId}
            api_host={api_host}
          />
        );
      })}
    </Wrapper>
  );
};
