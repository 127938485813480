import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ActionButton, ActionButtonsDiv, DetailsViewDiv } from "../component";
import {
  ContentDiv,
  Description,
  DescriptionDiv,
  NavIconDiv,
  ProductImage,
  ProductTitle,
} from "./components";
import { Icon } from "../../../../../utils/components/Icons";
import AppContext from "../../../../../utils/context/AppContext";
import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../../messageSlice";
import {
  setDetailsViewData,
  setDetailView,
  setShowDetailView,
} from "../../../../../widgetSlice";

export const TreatmentDetailsView = () => {
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;
  const detailsViewData = useSelector(
    (state) => state.widgetState.detailsViewData
  );
  const { title, image_url, description, info_url, button_payload_text, lang } =
    detailsViewData;
  const dispatch = useDispatch();

  return (
    <>
      <DetailsViewDiv lang={lang}>
        <ContentDiv>
          <NavIconDiv
            onClick={() => {
              dispatch(setDetailsViewData({}));
              dispatch(setDetailView(null));
              dispatch(setShowDetailView(false));
            }}
          >
            <Icon name="arrow_left" />
          </NavIconDiv>
          <ProductTitle> {title}</ProductTitle>
        </ContentDiv>
        <ContentDiv>
          <ProductImage src={image_url} alt="treatment" />
        </ContentDiv>
        <DescriptionDiv dir="auto">
          <Description dir="auto">{description}</Description>
        </DescriptionDiv>
      </DetailsViewDiv>
      <ActionButtonsDiv>
        <ActionButton
          as="a"
          className="bot-text-md bot-flex bot-w-1/2 bot-items-center bot-justify-center bot-gap-2 bot-py-3 bot-font-bold bot-text-white "
          href={info_url}
          target={"_blank"}
          rel="noreferrer"
          dir="auto"
        >
          <Icon name="info_icon" />
          <span>{lang === "en" ? "Know More" : "تعرف أكثر"}</span>
        </ActionButton>
        <ActionButton
          type="button"
          className="bot-text-md bot-flex bot-w-1/2 bot-items-center bot-justify-center bot-gap-2 bot-py-3 bot-font-bold bot-text-white"
          dir="auto"
          onClick={() => {
            dispatch(
              addMessage({
                text: title,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );
            dispatch(setDetailsViewData({}));
            dispatch(setDetailView(null));
            dispatch(setShowDetailView(false));
            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: button_payload_text,
                sender: userId,
              })
            );
          }}
        >
          <Icon name="calender_icon" />
          <span>{lang === "en" ? "Book Appointment" : "موعد الكتاب"}</span>
        </ActionButton>
      </ActionButtonsDiv>
    </>
  );
};
