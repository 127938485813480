import React from "react";

const AppContext = React.createContext({
  init: "/greet",
  path: "/socket.io",
  botAvatar: "",
  widgetPrimaryColor: "#0f153f",
  botHeaderBackgroundColor: "#007aff",
  botTitleColor: "#fff",
  botSubtitleColor: "#fff",
  botMessageColor: "#e13745",
  botMessageBackgroundColor: "#e3e7ee",
  userMessageColor: "#fff",
  userMessageBackgroundColor: "#007aff;",
  resizeIconColor: "#fff",
  sendButtonColor: "#007aff",
  launcherColor: "#007aff",
  launcherIconColor: "#fff",
  buttonTextColor: "red",
  buttonColor: "red",
  buttonBorderColor: "red",
  buttonHoverTextColor: "",
  buttonHoverColor: "",
  botTypingColor: "",
  widgetHeight: "",
  widgetWidth: "",
  userId: "",
  rasaServer: "",
  rasaServerUrl: "",
  customMetadata: {},
  popUpMessage: {},
  defaultBotMessages: {},
  api_host: "",
  rasaServerHost: "",
  launcher: {
    popupMessage: "Hi there! How can I help you?",
  },
});

export default AppContext;
