import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import { useRef } from "react";
import styled, { ThemeProvider } from "styled-components";

import { Header } from "./Header";
import { Keypad } from "./Keypad";
import { MessageContainer } from "./MessageContainer";

import { setUserId, setUserLocationMetadata } from "./widgetSlice";
import { getUserLocationMetadata } from "./utils/api";
import AppContext from "./utils/context/AppContext";
import { Launcher } from "./Launcher";
import { LiveChatKeypad } from "./Keypad/LiveChatKeypad";
import { DetailView } from "./MessageContainer/BotMessage/Cards/DetailView";
import { AgentTypingMessage } from "./MessageContainer/AgentTyping";

const BotContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 1.25rem;
  z-index: 9999;
  display: flex;
  height: 550px;
  width: 380px;
  flex-direction: column;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor} !important;
  border-radius: 1.5rem;
  background-color: #fff;
  -webkit-animation: slide-top 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
  animation: slide-top 1s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
    }
  }
  @media (max-width: 600px) {
    right: 0;
    height: calc(100% - 100px);
    width: calc(100% - 5px);
  }
`;

export const Widget = (props) => {
  const dispatch = useDispatch();
  let {
    toggleWidget,
    showDetailView,
    userId: _userId,
    socketMode,
    detailView,
  } = useSelector((state) => state.widgetState);

  let userLocationMetadata = useSelector(
    (state) => state.widgetState.userLocationMetadata
  );
  let { userId, api_host } = props;
  let userIdRef = useRef(_userId);
  useEffect(() => {
    if (userIdRef.current === null) {
      if (userId === null) {
        // if user id passed as prop
        userIdRef.current = nanoid();
      } else {
        userIdRef.current = userId;
      }

      dispatch(setUserId(userIdRef.current));
    } else {
    }
    if (userLocationMetadata === null) {
      const fetchLocationMetadata = async () => {
        let locationMetadata = await getUserLocationMetadata({ api_host });
        dispatch(setUserLocationMetadata(locationMetadata));
      };

      fetchLocationMetadata();
    }
  }, [api_host, dispatch, toggleWidget, userId, userLocationMetadata]);

  return (
    <AppContext.Provider value={{ ...props, userId: userIdRef.current }}>
      <ThemeProvider theme={props}>
        {toggleWidget && (
          <BotContainer key="bot_widget">
            <Header />
            <MessageContainer />
            <AgentTypingMessage showDetailView={showDetailView} />
            {socketMode === "liveAgent" ? (
              <LiveChatKeypad showDetailView={showDetailView} />
            ) : (
              <Keypad showDetailView={showDetailView} />
            )}
            {showDetailView && <DetailView detailView={detailView} />}
          </BotContainer>
        )}
        <Launcher />
      </ThemeProvider>
    </AppContext.Provider>
  );
};
