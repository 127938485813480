import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 0.25rem /* 8px */;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const BotIconDiv = styled.div`
  display: flex;
  width: 1.5rem /* 24px */;
  align-items: flex-end;
`;

export const BotIcon = styled.img`
  height: 1.5rem /* 24px */;
  width: 1.5rem /* 24px */;
  border-radius: 9999px;
  border: 1px solid ${(props) => props.borderColor};
  display: ${(props) => (props.showIcon ? "" : "none")};
`;

export const MessageDiv = styled.div`
  display: flex !important;
  min-width: 10%;
  max-width: 70%;
  flex-direction: column;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const Message = styled.div`
  width: fit-content;
  font-size: 13.5px; /* 14px */
  line-height: 1.15rem; /* 20px */
  white-space: pre-line;
  overflow-wrap: break-word;
  background-color: #e5e7eb;
  color: ${(props) => props.color} !!important;
  padding: 10px 14px 10px 14px;
  border-radius: ${(props) => {
    const { position } = props;
    if (position === "message start") {
      return "20px 20px 20px 5px";
    }
    if (position === "message end") {
      return "5px 20px 20px 20px";
    }
    if (position === "message") {
      return "5px 20px 20px 5px";
    }
    return "20px";
  }};

  a {
    color: inherit  !important;
    cursor: pointer !important;
    text-decoration: none !important;
  }

  p {
    display: block !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  ul {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 0px !important;
    list-style: disc !important;
    list-style-position: inside !important;
  }

  hr {
    display: block !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
    overflow: hidden !important;
    border-style: inset !important;
    border: 0.5px solid #9ca3af !important;
  }
`;

export const TimestampDiv = styled.div`
  font-size: 10px;
  font-style: italic;
  color: #6b7280;
`;
