import React from "react";
import { createRoot } from "react-dom/client";
import { ConnectedWidget } from "./ConnectedWidget";

const node = document.createElement("div");
node.setAttribute("id", "botWidget");
document.body.appendChild(node);
const mountElement = document.getElementById("botWidget");
const root = createRoot(mountElement);

const api_host =
  process.env.REACT_APP_ENV === "PROD"
    ? process.env.REACT_APP_PROD_API_HOST
    : process.env.REACT_APP_DEV_API_HOST;
const rasaServerUrl =
  process.env.REACT_APP_ENV === "PROD"
    ? process.env.REACT_APP_PROD_BOT_URL
    : process.env.REACT_APP_DEV_BOT_URL;

const props = {
  widgetPrimaryColor: "#0f153f",
  widgetPrimaryColorShade: "#e7e8ec",
  userId: null,
  botTitle: "AESMC Helpdesk",
  botSubtitle: "Health Care Assistant",
  rasaServerUrl,
  botAvatar:"",
  init: "/show_lang_option",
  botMessageColor: "#0f153f",
  userMessageColor: "#0f153f",
  api_host,
  header: {
    titleColor: "#fff",
    subTitleColor: "#fff",
    dropDownColor: "#fff",
    dropDownItemColor: "#fff",
  },
  launcher: {
    popupMessage: "Hi There 👋, How can I help you?",
    iconColor: "#fff",
  },
};
root.render(<ConnectedWidget {...props} />);
