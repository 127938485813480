import styled, { keyframes } from "styled-components";

export const StyledScrollBar = styled.div`
  /* width */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.widgetPrimaryColor} transparent;
  
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: "transparent"

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    
    border-radius:20px;
    background-clip: "padding-box";
    background: ${(props) =>
      props.theme.widgetPrimaryColor}; /* color of the scroll thumb */,
    border-radius: "20px"; /* roundness of the scroll thumb */,
    border: "none"; /* creates padding around scroll thumb */,
  }
`;
export const hoverAnimation = keyframes`
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
`;
export const AnimateHoverDiv = styled.div`
  &:hover {
    animation: ${hoverAnimation} 2s ease 0s 1 normal forwards;
  }
`;
