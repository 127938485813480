import styled from "styled-components";

export const Wrapper = styled.div`
  margin-left: 1rem /* 16px */;
  display: flex;
  height: fit-content;
  width: 15rem /* 240px */;
  display: flex;
  flex-direction: column;
  align-items: center;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  border-radius: 0.75rem /* 12px */;
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 1px solid ${(props) => props.color};
`;

export const Title = styled.div`
  font-size: 13px;
`;

export const Price = styled.div`
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
  font-weight: 500;
  color: ${(props) => props.color};
`;
export const Divider = styled.div`
  width: 100%;
  border: 0.8px solid #e5e7eb;
`;

export const FeaturesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
`;

export const Feature = styled.div`
  font-size: 13px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const ActionButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  display: inline-flex;
  margin-top: 0.5rem;
  align-items: center;
  border-radius: 9999px;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  padding-top: 0.625rem /* 10px */;
  padding-bottom: 0.625rem /* 10px */;
  text-align: center;
  font-weight: 500;
  color: #fff;
  border: 0;
  background-color: ${(props) => props.color};
`;
