import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 0.5rem /* 8px */;
  padding: 0.5rem /* 8px */;
  padding-left: 1rem /* 8px */;
`;

export const TileDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 5rem /* 80px */;
  width: 6rem /* 96px */;
  cursor: pointer;
  align-items: center;
  border-radius: 0.75rem /* 12px */;
  padding: 0.25rem /* 8px */;
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor};
  color: ${(props) => props.theme.widgetPrimaryColor};
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColorShade};
  }
`;

export const IconDiv = styled.div`
  width: fit-content;
  margin-top: 0.5rem;
`;

export const Title = styled.div`
  align-items: center;
  text-align: center;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 600;
  margin-top: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
