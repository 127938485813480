import dayjs from "dayjs";
import { useDispatch } from "react-redux";

import {
  addMessage,
  fetchBotResponse,
  setUserTypingPlaceholder,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";
import { forwardRef, useContext, useState } from "react";
import { setHours, setMinutes } from "date-fns";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import AppContext from "../../../utils/context/AppContext";
import { CustomInput, SubmitButton, Wrapper } from "./components";

export default function DateTimePicker({ payload, lang }) {
  const _theme = useContext(AppContext);
  const { rasaServerUrl, userId } = _theme;

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <CustomInput
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    />
  ));

  return (
    <Wrapper>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
        }}
        showTimeSelect
        showIcon
        customInput={<ExampleCustomInput />}
        minDate={new Date()}
        minTime={setMinutes(setHours(new Date(startDate), 9), 0)}
        maxTime={setMinutes(setHours(new Date(startDate), 21), 0)}
        filterTime={filterPassedTime}
        todayButton="TODAY"
        dateFormat="MMM d, yyyy h:mm aa"
        shouldCloseOnSelect={false}
        popperModifiers={[
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
      >
        <SubmitButton
          onClick={() => {
            const userInput = dayjs(startDate).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            dispatch(toggleUserTyping(false));
            dispatch(toggleBotTyping(true));
            dispatch(
              setUserTypingPlaceholder("Please wait for bot response...")
            );
            dispatch(
              addMessage({
                text: userInput,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: `${payload}${JSON.stringify({
                  datetime: userInput,
                  time: userInput,
                })}`,
                sender: userId,
              })
            );
          }}
        >
          {lang === "ar" ? "إرسال" : "Submit"}
        </SubmitButton>
      </DatePicker>
    </Wrapper>
  );
}
