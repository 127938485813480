import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const OfferCardWrapper = styled.div`
  display: inline-flex;
  width: 13rem /* 208px */;
  flex: none;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex-direction: column;
  align-items: center;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  border-radius: 1rem /* 24px */;
  padding: 0.5rem /* 8px */;
  background: #fff;
  padding-top: 1rem /* 16px */;
  padding-bottom: 0.5rem /* 16px */;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
  margin-right: 10px;
`;

export const OfferImageDiv = styled.div`
  position: relative;
  border-radius: 0.5rem /* 24px */;
`;
export const OfferImage = styled.img`
  height: 100% /* 112px */;
  width: 100% /* 112px */;
  object-fit: cover;
  border-radius: 0.5rem /* 24px */;
`;

export const OffersContentDiv = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  width: 100%;
`;

export const Title = styled.span`
  flex-grow: 1;
  width: 50%;
  font-size: 13px;
  overflow-wrap: break-word;
`;

export const ActionButton = styled.button`
  border-radius: 9999px;
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 500;
  margin-top: 0.5rem;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${(props) => props.widgetPrimaryColor};
  color: ${(props) =>
    props.title === "Main Menu" ? "#fff" : props.theme.widgetPrimaryColor};
  background-color: ${(props) =>
    props.title === "Main Menu" ? props.theme.widgetPrimaryColor : "#fff"};

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: ${(props) => "white"};
  }
`;
