import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const NavButton = styled.button`
  position: absolute;
  top: 45%;
  z-index: 20;
  display: inline-flex;
  transform: translateY(-45%);
  align-items: center;
  border-radius: 9999px;
  padding: 0.625rem /* 10px */;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor};
  background-color: #fff;
  color: ${(props) => props.theme.widgetPrimaryColor};
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: white;
  }
  @media (max-width: 600px) {
    visibility: hidden;
  }
`;

export const NavButtonLeft = styled(NavButton)`
  left: 10px;
`;
export const NavButtonRight = styled(NavButton)`
  right: 10px;
`;

export const ContactCardsWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  cursor: default;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledContactCards = styled.div`
  margin-left: 0.5rem /* 8px */;
  display: flex;
  width: 20rem /* 320px */;
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
  scroll-padding-left: 1.5rem /* 24px */;
  scroll-padding-right: 1.5rem /* 24px */;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 0.25rem /* 4px */;
  padding-left: 1.25rem /* 20px */;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContactCardWrapper = styled.div`
  display: inline-flex;
  width: 13rem /* 208px */;
  flex: none;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex-direction: column;
  align-items: center;
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  border-radius: 1.5rem /* 24px */;
  padding: 0.5rem /* 8px */;
  background: #fff;
  padding-top: 1rem /* 16px */;
  padding-bottom: 0.5rem /* 16px */;
  border: 1px solid ${(props) => props.theme.widgetPrimaryColor};
  margin-right: 10px;
`;

export const ProfilePic = styled.img`
  height: 7rem /* 112px */;
  width: 7rem /* 112px */;
  border-radius: 9999px;
  border: 0.5px solid ${(props) => props.theme.widgetPrimaryColor};
`;

export const NameDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

export const Name = styled.span`
  overflow-wrap: break-word;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  color: ${(props) => props.theme.widgetPrimaryColor};
`;

export const SpecializationText = styled.p`
  text-align: center !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
  display: block !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
`;

export const QualificationText = styled.p`
  font-size: 0.7rem !important /* 12px */;
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
`;

export const ActionButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`;

export const ActionButton = styled.button`
  border-radius: 9999px;
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
  font-weight: 500;
  margin-top: 0.5rem;

  border: 1px solid ${(props) => props.widgetPrimaryColor};
  color: ${(props) =>
    props.title === "Main Menu" ? "#fff" : props.theme.widgetPrimaryColor};
  background-color: ${(props) =>
    props.title === "Main Menu" ? props.theme.widgetPrimaryColor : "#fff"};

  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: ${(props) => "white"};
  }
`;
