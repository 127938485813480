import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 0.25rem /* 8px */;
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
`;

export const BotIconDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 2rem /* 20px */;
`;
export const ButtonsWrapper = styled.div`
  margin-top: 0.5rem /* 8px */;
  display: flex;
  gap: 0.5rem /* 8px */;
  align-self: flex-start;
  white-space: pre-line;
  overflow-wrap: break-word;
  font-size: 13px;
  color: white;
`;
export const QuickRepliesWrapper = styled.div`
  margin-top: 0.5rem /* 8px */;
  display: flex;
  gap: 0.5rem /* 8px */;
  width: fit-content;
  min-width: 10%;
  max-width: 75%;
  flex-wrap: wrap;
  align-self: flex-start;
  white-space: pre-line;
  overflow-wrap: break-word;
  font-size: 13px;
  color: white;
`;
// style={{
//     border: `1px solid ${widgetPrimaryColor}`,
//     color: item.title === "Main Menu" ? "white" : widgetPrimaryColor,
//     backgroundColor:
//       item.title === "Main Menu" ? widgetPrimaryColor : "white",
//   }}
export const Button = styled.button`
  border: 1px solid ${(props) => props.widgetPrimaryColor || "#0f153f"};
  color: ${(props) =>
    props.title === "Main Menu" ? "#fff" : props.theme.widgetPrimaryColor};
  align-self: flex-start;
  border-radius: 9999px;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  background-color: ${(props) =>
    props.title === "Main Menu" ? props.theme.widgetPrimaryColor : "#fff"};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.widgetPrimaryColor};
    color: ${(props) => "white"};
  }
`;
