import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 0.5rem;
`;

export const MessageDiv = styled.div`
  display: flex;
  max-width: 70%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Message = styled.div`
  color: ${(props) => props.color} !important;
  border: 0.5px solid ${(props) => props.color};
  background: #fff;
  align-items: flex-end;
  overflow-wrap: break-word;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 20px;
  border-width: 0.5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13.5px;
  p {
    display: block !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  ul {
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 0px !important;
    list-style: disc !important;
    list-style-position: inside !important;
  }
  a {
    color: inherit !important;
    cursor: pointer !important;
    text-decoration: none !important;
  }
`;

export const TimestampDiv = styled.div`
  font-size: 10px;
  font-style: italic;
  color: #6b7280;
`;
