import dayjs from "dayjs";

export const generateOrderId = (date) => {
  let now = date ? new Date(date).getTime().toString() : Date.now().toString();

  // pad with additional random digits
  if (now.length < 14) {
    const pad = 14 - now.length;
    now += randomNumber(pad);
  }
  return now;
};
function randomNumber(length) {
  return Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  ).toString();
}

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const formattedTs = (ts) => {
  return dayjs(ts).format("ddd, MMM D, h:mm A");
};

export const base64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const getFileType = (type) => {
  switch (type) {
    case "application/pdf":
      return "PDF";
    case "image/jpeg":
      return "JPG";
    case "image/png":
      return "PNG";
    default:
      return "FILE";
  }
};

export const getBytes = (a) => {
  let b = 0,
    c = parseInt(a, 10) || 0;
  for (; 1024 <= c && ++b; ) c /= 1024;
  return (
    c.toFixed(10 > c && 0 < b ? 1 : 0) +
    " " +
    ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][b]
  );
};

// messages processer functions
export function isCarousel(message) {
  return (
    Object.keys(message).includes("attachment") &&
    Object.keys(message.attachment).includes("type") &&
    message.attachment.type === "template" &&
    Object.keys(message.attachment).includes("payload") &&
    Object.keys(message.attachment.payload).indexOf("template_type") >= 0 &&
    message.attachment.payload.template_type === "generic" &&
    Object.keys(message.attachment.payload).indexOf("elements") >= 0 &&
    message.attachment.payload.elements.length > 0
  );
}

export function isVideo(message) {
  return (
    Object.keys(message).includes("attachment") &&
    Object.keys(message.attachment).includes("type") &&
    message.attachment.type === "video"
  );
}

export function isImage(message) {
  return (
    Object.keys(message).includes("attachment") &&
    Object.keys(message.attachment).includes("type") &&
    message.attachment.type === "image"
  );
}

export function isText(message) {
  return (
    Object.keys(message).length === 1 && Object.keys(message).includes("text")
  );
}

export function isButtons(message) {
  return (
    Object.keys(message).length === 2 &&
    Object.keys(message).includes("text") &&
    (Object.keys(message).includes("quick_replies") ||
      Object.keys(message).includes("buttons"))
  );
}
