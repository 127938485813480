import { useContext, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";

import AppContext from "../../../utils/context/AppContext";

import {
  addMessage,
  fetchBotResponse,
  setUserTypingPlaceholder,
  toggleBotTyping,
  toggleUserTyping,
} from "../../messageSlice";

import {
  ActionsDiv,
  FloatingLabel,
  InputIconDiv,
  MenuButton,
  RowDiv1,
  RowDiv2,
  StyledInput,
  StyledPhoneInput,
  SubTitleText,
  SubmitButton,
  TitleDiv,
  Wrapper,
} from "./components";
import { Icon } from "../../../utils/components/Icons";

export const BookingForm = ({ payload, user_details, lang }) => {
  const theme = useContext(AppContext);
  const {
    rasaServerUrl,
    userId,
  } = theme;
  const [validEmail, setValidEmail] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  let msg = lang === "ar" ? "القائمة الرئيسية" : "Main Menu";
  useEffect(() => {
    dispatch(toggleUserTyping(false));
    dispatch(
      setUserTypingPlaceholder(
        lang === "ar"
          ? "الرجاء إدخال التفاصيل الخاصة بك."
          : "Please enter your details."
      )
    );
    if (user_details) {
      if (user_details?.name) {
        setName(user_details.name);
      }
      if (user_details?.email) {
        setEmail(user_details.email);
        setValidEmail(true);
      }
      if (user_details?.phone_number) {
        setPhoneNumber(user_details.phone_number);
        setValidPhoneNumber(true);
      }
    }
    return () => {};
  }, [dispatch, lang, user_details]);

  return (
    <Wrapper>
      <RowDiv1>
        <TitleDiv>
          {lang === "ar" ? "معلومات التواصل" : "Contact Information"}
        </TitleDiv>
        <SubTitleText>
          {lang === "ar"
            ? "الرجاء إدخال تفاصيل الاتصال الخاصة بك"
            : "Please enter your contact details"}
        </SubTitleText>
      </RowDiv1>
      <RowDiv2>
        <FloatingLabel>{lang === "ar" ? "اسم" : "Name"}</FloatingLabel>
        <StyledInput
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <InputIconDiv>
          <Icon name="user_icon" />
        </InputIconDiv>
      </RowDiv2>
      <RowDiv2>
        <FloatingLabel htmlFor="floating_outlined">
          {lang === "ar" ? "عنوان البريد الالكترونى" : "Email Address"}
        </FloatingLabel>
        <StyledInput
          value={email}
          onChange={(e) => {
            let email = e.target.value;
            setEmail(email.trim());
            setValidEmail(/\S+@\S+\.\S+/.test(email));
          }}
        />
        <InputIconDiv>
          <Icon name="email_icon" />
        </InputIconDiv>
      </RowDiv2>

      <RowDiv2>
        <FloatingLabel htmlFor="floating_outlined">
          {lang === "ar" ? "رقم الهاتف" : "Phone Number"}
        </FloatingLabel>
        <StyledPhoneInput
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e);
            if (e !== undefined) {
              setValidPhoneNumber(isValidPhoneNumber(e));
            } else {
              setValidPhoneNumber(false);
            }
          }}
        />
        <InputIconDiv>
          <Icon name="phone_icon" />
        </InputIconDiv>
      </RowDiv2>

      <ActionsDiv>
        <SubmitButton
          validInput={name.trim().length > 0 && validEmail && validPhoneNumber}
          onClick={(e) => {
            e.preventDefault();

            if (name.trim().length > 0 && validEmail && validPhoneNumber) {
              let user_details_ar = `- اسم: **${name}**\n - البريد الإلكتروني: **${email}**\n - هاتف: **${phoneNumber}**`;
              let user_details_en = `- Name: **${name}**\n - Email: **${email}**\n - Phone: **${phoneNumber}**`;
              dispatch(
                addMessage({
                  text: lang === "ar" ? user_details_ar : user_details_en,
                  sender: "USER",
                  messageType: "text",
                  ts: new Date(),
                })
              );
              dispatch(toggleUserTyping(false));
              dispatch(toggleBotTyping(true));
              dispatch(
                setUserTypingPlaceholder("Please wait for bot response...")
              );
              const message = `${payload}${JSON.stringify({
                name: name.trim(),
                email: email,
                phone_number: phoneNumber,
                booking_type: user_details?.booking_type,
                booking_prompted: user_details?.booking_prompted,
              })}`;
              dispatch(
                fetchBotResponse({
                  rasaServerUrl,
                  message,
                  sender: userId,
                })
              );
            }
          }}
        >
          {lang === "ar" ? "إرسال" : "Submit"}
        </SubmitButton>
        <MenuButton
          onClick={(e) => {
            e.preventDefault();

            const _payload = {
              text: msg,
              sender: "USER",
              messageType: "text",
              ts: new Date(),
            };
            dispatch(addMessage(_payload));
            dispatch(addMessage(msg));
            dispatch(toggleUserTyping(false));
            dispatch(toggleBotTyping(true));

            dispatch(
              setUserTypingPlaceholder("Please wait for bot response...")
            );
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: msg,
                sender: userId,
              })
            );
          }}
        >
          {msg}
        </MenuButton>
      </ActionsDiv>
    </Wrapper>
  );
};
