/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  endBotConversations,
  fetchBotResponse,
  setLastMessageTs,
  toggleBotTyping,
  toggleUserTyping,
} from "../MessageContainer/messageSlice";
import {
  setDetailView,
  setShowDetailView,
} from "../widgetSlice";
import {
  Wrapper,
  TypingArea,
  SendButton,
  SendButtonIcon,
  ShortcutDiv,
} from "./components";
import AppContext from "../utils/context/AppContext";
import { Icon } from "../utils/components/Icons";
import { useDetectClick } from "../utils/hooks/useDetectClick";
import { Shortcuts } from "./Shortcuts";

const INACTIVITY_TIMEOUT = 300 * 1000;


export const Keypad = ({ showDetailView }) => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const { userTyping, lastMessageTs } = useSelector(
    (state) => state.messageState
  );
  const userTypingPlaceholder = useSelector(
    (state) => state.messageState.userTypingPlaceholder
  );
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId, botMessageColor } = theme;

  useEffect(() => {
    const inactivityTimeout = setTimeout(() => {
      if (lastMessageTs) {
        if (new Date() - lastMessageTs > INACTIVITY_TIMEOUT) {
          console.log("user is inactive");
          dispatch(setLastMessageTs(null));
          dispatch(
            endBotConversations({
              rasaServerUrl,
              message: "end_conversation",
              sender: userId,
            })
          );
          dispatch(setDetailView({}));
          dispatch(setShowDetailView(false));
        }
      }
    }, INACTIVITY_TIMEOUT);
    return () => {
      clearTimeout(inactivityTimeout);
    };
  }, [dispatch, lastMessageTs, rasaServerUrl, userId]);

  const handleSubmit = async () => {
    if (userInput.length > 0) {
      const payload = {
        text: userInput,
        sender: "USER",
        messageType: "text",
        ts: new Date(),
      };
      dispatch(
        fetchBotResponse({
          rasaServerUrl,
          message: userInput.trim(),
          sender: userId,
        })
      );

      dispatch(addMessage(payload));
      dispatch(toggleUserTyping(false));
      dispatch(toggleBotTyping(true));
      setUserInput("");
    }
  };
  const dropdownRef = useDetectClick({
    setShowModal: setShowDropdown,
  });
  return (
    <>
      <Wrapper key="keypad" showDetailView={showDetailView}>
        <ShortcutDiv
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          showDropdown={showDropdown}
        >
          <Icon name="shortcuts" />
        </ShortcutDiv>
        <TypingArea
          id="chat"
          rows="1"
          userTyping={userTyping}
          placeholder={userTypingPlaceholder}
          value={userInput}
          onChange={(e) => {
            setUserInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
          readOnly={!userTyping}
        />
        <SendButton
          userInput={userInput}
          color={botMessageColor}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <SendButtonIcon />
        </SendButton>
      </Wrapper>
      {showDropdown && (
        <Shortcuts
          setShowDropdown={setShowDropdown}
          showDropdown={showDropdown}
        />
      )}
    </>
  );
};
