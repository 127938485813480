import { useContext } from "react";

import { useDispatch, useSelector } from "react-redux";

import AppContext from "../../../../../utils/context/AppContext";
import {
  setDetailsViewData,
  setDetailView,
  setShowDetailView,
} from "../../../../../widgetSlice";
import {
  addMessage,
  fetchBotResponse,
  toggleBotTyping,
  toggleUserTyping,
} from "../../../../messageSlice";
import {
  BioDiv,
  BioDivText1,
  BioDivText2,
  BioDivText3,
  BioDivText3Div,
  ContentDiv1,
  ContentDiv2,
  ContentDiv2Text1,
  ContentDiv2Text2,
  Divider,
  Header,
  HeaderText,
  NavIconDiv,
  ProfileImage,
} from "./components";
import { Icon } from "../../../../../utils/components/Icons";
import { MarkdownText } from "../../../../../utils/components/MarkdownText";
import { ActionButton, ActionButtonsDiv, DetailsViewDiv } from "../component";

export const DoctorDetailsView = () => {
  const theme = useContext(AppContext);
  const { rasaServerUrl, userId } = theme;
  const detailsViewData = useSelector(
    (state) => state.widgetState.detailsViewData
  );
  const {
    arabic_name,
    expertise,
    expertise_ar,
    image_url,
    lang_known,
    lang_known_ar,
    name,
    payload,
    qualification,
    specialization,
    specialization_ar,
    profile_link,
    lang,
    button_payload_text,
  } = detailsViewData;
  const dispatch = useDispatch();

  return (
    <>
      <DetailsViewDiv lang={lang}>
        <Header>
          <NavIconDiv
            onClick={() => {
              dispatch(setDetailsViewData({}));
              dispatch(setDetailView(null));
              dispatch(setShowDetailView(false));
            }}
          >
            <Icon name="arrow_left" />
          </NavIconDiv>
          <HeaderText>
            {lang === "en" ? "Doctor's Profile" : "الملف الشخصي للطبيب"}
          </HeaderText>
        </Header>
        <ContentDiv1>
          <ProfileImage src={image_url} alt="doctor_profile" />
          <BioDiv dir="auto">
            <BioDivText1>{lang === "en" ? name : arabic_name}</BioDivText1>
            <BioDivText2>
              {lang === "en" ? specialization : specialization_ar}
            </BioDivText2>
            <Divider />
            <BioDivText3Div
              dir="auto"
            >
              <span>
                <BioDivText3 >
                  {lang === "en" ? "Languages Known" : "انا اعرف اللغات"}
                </BioDivText3>{" "}
                : {lang === "en" ? lang_known : lang_known_ar}
              </span>
              <span>
                <BioDivText3 >
                  {lang === "en" ? "Qualification" : "مؤهلات"}
                </BioDivText3>{" "}
                : {qualification}
              </span>
            </BioDivText3Div>
          </BioDiv>
        </ContentDiv1>
        <ContentDiv2 dir="auto">
          <ContentDiv2Text1 >
            {lang === "en" ? "Expertise" : "خبرة"}
          </ContentDiv2Text1>
          <ContentDiv2Text2 lang={lang} dir="auto">
            <MarkdownText text={lang === "en" ? expertise : expertise_ar} />
          </ContentDiv2Text2>
        </ContentDiv2>
      </DetailsViewDiv>
      <ActionButtonsDiv>
        <ActionButton
          as="a"
          href={profile_link}
          target={"_blank"}
          rel="noreferrer"
          dir="auto"
        >
          <Icon name="info_icon" />
          <span>{lang === "en" ? "Know More" : "تعرف أكثر"}</span>
        </ActionButton>
        <ActionButton
          type="button"
          dir="auto"
          onClick={() => {
            dispatch(
              addMessage({
                text: `${button_payload_text}${
                  lang === "ar" ? arabic_name : name
                }`,
                sender: "USER",
                messageType: "text",
                ts: new Date(),
              })
            );
            dispatch(setDetailsViewData({}));
            dispatch(setDetailView(null));
            dispatch(setShowDetailView(false));
            dispatch(toggleBotTyping(true));
            dispatch(toggleUserTyping(false));
            dispatch(
              fetchBotResponse({
                rasaServerUrl,
                message: payload,
                sender: userId,
              })
            );
          }}
        >
          <Icon name="calender_icon" />
          <span>{lang === "en" ? "Book Appointment" : "موعد الكتاب"}</span>
        </ActionButton>
      </ActionButtonsDiv>
    </>
  );
};
