import { useContext } from "react";
import { useSelector } from "react-redux";
import BotLogo from "../../../../assets/img/aesmcLogo.png";

import AppContext from "../../../utils/context/AppContext";
import { BotIcon, BotIconDiv, Wrapper } from "../TextMessage/components";
import { Dot1, Dot2, Dot3, MessageDiv } from "./components";

export const BotTyping = () => {
  const botTyping = useSelector((state) => state.messageState.botTyping);
  const { botMessageColor } = useContext(AppContext);
  return (
    botTyping && (
      <Wrapper>
        <BotIconDiv className={`bot-flex bot-w-5 bot-items-end`}>
          <BotIcon
            className={`bot-h-5 bot-w-5  bot-rounded-full`}
            style={{ border: `1px solid ${botMessageColor}` }}
            src={BotLogo}
            alt="Bot Logo"
            showIcon={true}
          />
        </BotIconDiv>
        <MessageDiv>
          <Dot1 color={botMessageColor} />
          <Dot2 color={botMessageColor} />
          <Dot3 color={botMessageColor} />
        </MessageDiv>
      </Wrapper>
    )
  );
};
