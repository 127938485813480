import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

import {
  setPopupMessageShown,
  setShowLauncherPopupMsg,
  setToggleWidget,
} from "../widgetSlice";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  bottom: 3.5rem;
  right: 4rem;
  display: flex;
  width: 12rem;
  justify-content: flex-start;
  border-top-left-radius: 0.5rem /* 8px */;
  border-top-right-radius: 0.5rem /* 8px */;
  border-bottom-left-radius: 0.5rem /* 8px */;
  padding: 0.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width))
    var(--tw-ring-color);
  --tw-ring-color: rgb(0 0 0 / 0.05);
  background: #fff;
`;

const Message = styled.div`
  margin-top: 0.5rem /* 8px */;
  width: 24rem /* 384px */;
  text-align: left;
  font-weight: 500;
`;

const HideIcon = styled(XMarkIcon)`
  margin-left: auto;
  align-items: flex-start;
  margin-right: auto;
  height: 1.25rem /* 20px */;
  width: 1.25rem /* 20px */;
`;

export const LauncherPopup = ({
  showPopupMessage,
  popupMessageShown,
  dispatch,
  popUpMessage,
}) => {
  useEffect(() => {
    if (showPopupMessage === false && !popupMessageShown) {
      setTimeout(() => {
        dispatch(setShowLauncherPopupMsg(true));
        dispatch(setPopupMessageShown(true));
      }, 1000);
    }
  }, [dispatch, popupMessageShown, showPopupMessage]);

  return (
    showPopupMessage && (
      <Wrapper
        onClick={(e) => {
          dispatch(setShowLauncherPopupMsg(false));
          dispatch(setToggleWidget(true));
        }}
      >
        <Message>{popUpMessage}</Message>
        <div
          className=""
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setShowLauncherPopupMsg(false));
          }}
        >
          <HideIcon />
        </div>
      </Wrapper>
    )
  );
};
